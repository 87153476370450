import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { GetClientList } from "../../../app/actions/client";
import { GetLoggedInUser } from "../../../app/actions/user";
import { Client } from "../../../app/types/client";
import { User } from "../../../app/types/user";
import { Link } from 'react-router-dom';
import AuthVerifyOnClick from "../../../common/AuthVerifyOnClick";

type UsersProps = {
  GetClientList(userId: string): [];
  GetLoggedInUser(): [];
  Client: Client;
  CurrentUser: User;
};

class Users extends React.Component<UsersProps> {
  state = {
    userId: 0,
    loggedUsertype: 0,
    clientList: [],
    fullList: [],
    valcomm: true,
    redirect: null,
  };

  componentDidMount = async () => {
    document.body.classList.remove("login-bg");

    await this.props.GetLoggedInUser();
    this.setState({
      loggedUsertype: this.props.CurrentUser?.user_type_id,
      userId: this.props.CurrentUser?.id,
    });
    if (this.props.CurrentUser?.user_type_id !== 1) {
      this.setState({ valcomm: false });
    }

    let userTypeId = this.props.CurrentUser?.user_type_id || 0;
    if (
      [4,5].includes(userTypeId)
    ) {
      this.setState({ redirect: "/" });
    } 

    await this.props.GetClientList(this.state.userId.toString());
    this.setState({
      clientList: this.props.Client,
      fullList: this.props.Client,
    });
    if (this.state?.clientList[0] && this.state.loggedUsertype !== 1) {
      this.setState({
        redirect: `/users/client/${this.state?.clientList[0]["id"]}`,
      });
    }
  };
  searchClient = (e: React.KeyboardEvent<HTMLInputElement>) => {
    AuthVerifyOnClick();
    let query = (e.target as HTMLInputElement).value.toLowerCase();
    var staff = "VALCOMM Staff";
    if (staff.trim()?.toLowerCase().includes(query.trim())) {
      this.setState({ valcomm: true });
    } else {
      this.setState({ valcomm: false });
    }
    if (query.trim()) {
      let filteredData = this.state.fullList?.filter((userData) => {
        let searchStringCriteria: string = userData["name"];
        searchStringCriteria = searchStringCriteria + " " + userData["url_tag"];
        return searchStringCriteria?.toLowerCase().includes(query);
      });
      if (filteredData !== undefined) {
        this.setState({ clientList: filteredData });
      }
    } else {
      if (this.state.fullList !== undefined) {
        let filteredData = this.state.fullList;
        this.setState({ clientList: filteredData, valcomm: true });
      }
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    let valcomm = this.state.valcomm;
    return (
      <div className="row">
        <div className="dashboard-content-section">
          <div className="dashboard-header-section">
            <h2>Users & Groups</h2>
            <div className="dashboard-search-section">
              <div className="row">
                <div className="col-md-8">
                  <div className="search-wrapper">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search Client/User Group"
                      autoComplete="Off"
                      onKeyUp={this.searchClient}
                    />
                    <button
                      type="button"
                      name="search"
                      className="search-icon"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-data-section">
            <div className="table-responsive-xl">
              <table width="100%" className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {valcomm && (
                    <tr className="odd">
                      <td>
                        <Link to={`${process.env.PUBLIC_URL}/users/staff`}>
                          VALCOMM Staff
                        </Link>
                      </td>
                    </tr>
                  )}
                  {this.state?.clientList?.map((client, index) => {
                    return (
                      <tr
                        className={
                          index % 2 === 0
                            ? valcomm
                              ? `even`
                              : `odd`
                            : valcomm
                            ? `odd`
                            : `even`
                        }
                        key={client["id"]}
                      >
                        <td>
                          <Link
                            to={`${process.env.PUBLIC_URL}/users/client/${client["id"]}`}
                          >
                            {client["name"]}
                          </Link>
                        </td>
                      </tr>
                    );
                  })}

                  {this.state.clientList?.length === 0 &&
                    this.state.valcomm === false && (
                      <tr className="even">
                        <td className="not-available">No Data Available</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = (state: any) => ({
  Client: state.clientState.clientList,
  CurrentUser: state.loggedUserState.loggedInUser,
});

const MapDispatchToProps = (dispatch: any) => {
  return {
    GetClientList: (id: string) => dispatch(GetClientList(id)),
    GetLoggedInUser: () => dispatch(GetLoggedInUser()),
  };
};

export default connect(MapStateToProps, MapDispatchToProps)(Users);
