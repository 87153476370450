import * as React from 'react';
import { Link } from 'react-router-dom';

type ButtonProps = {
  id: any;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
  disabled?: any;
}

export const Button:React.FC<React.PropsWithChildren<ButtonProps>> = (props: React.PropsWithChildren<ButtonProps>) => {
  return(
    <button className="btn" id={props.id} onClick={props.onClick} disabled={props.disabled}>{props.children}</button>
  )
}

type SubmitButtonProps = {
  id: string;
  value: string;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
}

type LinkButtonProps = {
  to: string;
  value: string;
  class?: string;
}

export const SubmitButton:React.FC<React.PropsWithChildren<SubmitButtonProps>> = (props: React.PropsWithChildren<SubmitButtonProps>) => {
  return(
    <div className="form-row">
      <button type="submit" id={props.id} onClick={props.onClick}>{props.value}</button>
    </div>
    
  )
}

export const LoginButton:React.FC<React.PropsWithChildren<SubmitButtonProps>> = (props: React.PropsWithChildren<SubmitButtonProps>) => {
  return(
    <div className="form-row">
      <button type="submit" id={props.id} onClick={props.onClick}>{props.value}</button>
    </div>
    
  )
}

export const LinkButton:React.FC<React.PropsWithChildren<LinkButtonProps>> = (props: React.PropsWithChildren<LinkButtonProps>) => {
  return(
    <div className="form-row">
      <Link to={props.to} className={props.class}>{props.value}</Link>
    </div>
    
  )
}