import { Folder, FolderActions, FolderActionsType } from "../types/folder";

interface FolderState {
    folderList?: Folder[],
    parentList?: Folder[]
}

const initialState: FolderState = {
    folderList: undefined
}

const folderReducer = (state: FolderState = initialState, action: FolderActionsType): FolderState => {
    switch (action.type) {
        case FolderActions.GET_FOLDER_LIST:
            return {
                folderList: action.folders
            }
        case FolderActions.GET_FOLDER_HIERARCHY:
            return {
                folderList: action.folders
            }
        case FolderActions.GET_PARENT_HIERARCHY:
            return {
                parentList: action.folders
            }
        case FolderActions.SEARCH_OBJECTS:
            return {
                folderList: action.folders
            }
        default:
            return state;
    }
}
export default folderReducer;