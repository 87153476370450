import * as React from "react";
import { Link, Redirect } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";
import { appAPI } from "../../../app/api-conn";
import { Folder } from "../../../app/types/folder";
import { GetCommentList } from "../../../app/actions/comment";
import { GetParentHierarchy } from "../../../app/actions/folder";
import { GetLoggedInUser } from "../../../app/actions/user";
import { Comment } from "../../../app/types/comment";
import { User } from "../../../app/types/user";
import { connect } from "react-redux";
import { AddSignDialog } from "../../components/ModalDialog/AddSign";
import { EditFileNameDialog } from "../../components/ModalDialog/EditFileName";
import AuthVerifyOnClick from "../../../common/AuthVerifyOnClick";

type FileDetailPageProps = {
  GetParentHierarchy(parentId: string): [];
  GetCommentList(userId: string): [];
  GetLoggedInUser(): [];
  Comment: Comment;
  Parents: Folder;
  CurrentUser: User;
};

class FileDetailPage extends React.Component<
  FileDetailPageProps & RouteComponentProps<any>
> {
  state = {
    parentId: this.props?.match?.params?.id!,
    userId: "0",
    commentList: [],
    parentList: [],
    clientList: [{ name: "" }],
    parent: "",
    backPage: "",
    redirect: null,
    fileId: this.props?.match?.params?.id!,
    fileName: "File Name",
    fileLink: "",
    comment: "",
    signed_by: "",
    showSignModal: false,
    addSign: true,
    errorComment: "",
    showEditFileNameModal: false,
    idOfEditFileName: 0,
  };

  componentDidMount = async () => {
    document.body.classList.remove("login-bg");

    // Get logged-in user ID
    await this.props.GetLoggedInUser();
    this.setState({ userId: this.props.CurrentUser?.id?.toString() });

    // Get comment list for file
    await this.props.GetCommentList(this.state.fileId.toString());
    if(this.props.Comment)
      this.setState({ commentList: this.props.Comment });

    // Get Parent Object ID of current folder ID
    await this.getParentId();

    // Breadcrumb hierarchy listing
    await this.props.GetParentHierarchy(this.state.parentId.toString());
    this.setState({ parentList: this.props.Parents });

    // Set Back button URL
    if (this.state.parent) {
      this.setState({ backPage: "/browser/" + this.state.parent });
    } else {
      this.setState({ backPage: "/dashboard" });
    }
  };
  componentDidUpdate = () => {
    var ch = localStorage.getItem("Signed");
    if (ch !== "false") {
      this.getParentId();
      localStorage.setItem("Signed", "false");
    }
  };
  updateCommentList = async () => {
    await this.props.GetCommentList(this.state?.fileId?.toString());
    if(this.props.Comment)
      this.setState({ commentList: this.props.Comment });
  };
  getParentId = async () => {
    const URL = `/client/get-object/` + this.state?.fileId;
    await appAPI
      .get(URL, {
        headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
      })
      .then((response:any) => {
        if (response.status === 200 || response.status === 201) {
          let data = response?.data?.object;
          let link = response?.data?.link;
          if (
            this.props.CurrentUser?.user_type_id !== 1 &&
            this.props.CurrentUser?.client_id?.toString() !==
              data?.client_id.toString()
          ) {
            this.setState({ redirect: "/dashboard" });
          } else {
            this.setState({
              parent: data?.parent_id,
              fileName: data?.short_path,
              objectClient: data?.client_id,
              idOfEditFileName: data?.id
            });
            this.setState({ fileLink: link, signed_by: data?.signed_by_name });

            for (
              let sign = 0;
              sign < data?.signed_by_ids?.split(",").length;
              sign++
            ) {
              if (data?.signed_by_ids.split(",")[sign] === this.state.userId) {
                this.setState({ addSign: false });
              }
            }
          }
        } else {
          console.log("Something is wrong !!");
        }
      })
      .catch((error:any) => {
        console.log("Error: " + error?.response?.data?.message);
      });
  };
  handleComment = (e: any) => {
    AuthVerifyOnClick();
    this.setState({ comment: e.target.value });
  };
  signFile = () => {
    AuthVerifyOnClick();
    document.body.classList.add("modal-open");
    this.setState({ showSignModal: true });
  };
  closeSignModal = () => {
    AuthVerifyOnClick();
    this.setState({ showSignModal: false });
  };
  commentClick = async () => {
    AuthVerifyOnClick();
    this.setState({ errorComment: "" });

    if (this.state?.comment?.length > 0) {
      const inputObj = {
        user_id: parseInt(this.state.userId),
        object_id: parseInt(this.state.fileId),
        comment: this.state.comment,
      };

      // Call the API Method
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      };
      const URL = `/client/browsers/object/add-comment`;
      appAPI
        .post(URL, inputObj, { headers })
        .then((response:any) => {
          if (response.status === 200 || response.status === 201) {
            this.setState({ comment: "" });
            this.updateCommentList();
          } else {
            console.log("Something is wrong !!");
          }
        })
        .catch((error:any) => {
          console.log(error?.response?.data?.message);
        });
    } else {
      this.setState({ errorComment: "Please enter your comment." });
    }
  };

  editFileName = () => {
    AuthVerifyOnClick();
    document.body.classList.add("modal-open");
    this.setState({ showEditFileNameModal: true });
  };
  closeEditFileNameModal = () => {
    AuthVerifyOnClick();
    this.setState({ showEditFileNameModal: false });
  };
  updateList = async () => {
    AuthVerifyOnClick();
    await this.getParentId();
    
    setTimeout(() => {
      document.body.classList.remove("modal-open");
      this.setState({ showEditFileNameModal: false });
    }, 800);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <div className="row">
          <div className="dashboard-content-section">
            <div className="dashboard-header-section">
              <h2 className="file-name">{this.state.fileName}</h2>
              <div className="dashboard-search-section">
                <div className="row">
                  <div className="col-sm-4 col-md-4 col-lg-5">
                    <div className="search-section-back">
                      <Link to={this.state.backPage}>
                        <button type="button" name="create-button">
                          Go Back
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div className="col-sm-8 col-md-8 col-lg-7">
                    <div className="search-section-button icon-button">
                      <ul>
                        <li>
                          <button
                            type="button"
                            name="rename-file"
                            className="rename-file-button"
                            onClick={this.editFileName}
                          >
                            Rename File
                          </button>
                        </li>
                        <li>
                          <a
                            href={this.state.fileLink}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <button
                              type="button"
                              name="create-button"
                              className="download-button"
                            >
                              Download File
                            </button>
                          </a>
                        </li>
                        {this.state.addSign && (
                          <li>
                            <button
                              type="button"
                              name="create-button"
                              className="edit-button"
                              onClick={this.signFile}
                            >
                              Sign File
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dashboard-data-section">
                <section>
                  <nav>
                    <ol className="cd-breadcrumb">
                      <li>
                        <Link to={`/dashboard`}>Home</Link>
                      </li>

                      {this.state?.parentList
                        ?.reverse()
                        .map((object: Folder, index) => {
                          if (this.state.parentList.length === index + 1) {
                            return null;
                          } else {
                            return (
                              <li key={index}>
                                <Link to={`/browser/${object.id}`}>
                                  {object.short_path.replace("/", "")}
                                </Link>
                              </li>
                            );
                          }
                        })}
                    </ol>
                  </nav>
                </section>

                <div className="table-responsive-xl">
                  <table width="100%" className="table">
                    <thead>
                      <tr>
                        <th>Comments By</th>
                        <th>Comments On</th>
                        <th>Comment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state?.commentList?.map((comment, index) => {
                        return (
                          <tr
                            className={index % 2 === 0 ? `odd` : `even`}
                            key={comment["id"]}
                          >
                            <td>{comment["comment_by"]}</td>
                            <td>{comment["posted_date"]}</td>
                            <td>{comment["comment"]}</td>
                          </tr>
                        );
                      })}

                      {this.state.commentList.length === 0 && (
                        <tr className="even">
                          <td colSpan={3} className="not-available">
                            No Data Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="comment-wrapper">
                      <div className="form-row">
                        <label>Add a new comment:</label>
                        <textarea
                          name="comment"
                          value={this.state.comment}
                          onChange={this.handleComment}
                        ></textarea>
                        <span
                          className="dialog-error user-reg"
                          style={{ marginTop: 0 }}
                        >
                          {this.state.errorComment}
                        </span>
                      </div>
                      <div className="form-row">
                        <button
                          type="button"
                          name="comment"
                          className="floatright"
                          onClick={this.commentClick}
                        >
                          Add Comment
                        </button>
                      </div>
                      <div className="form-row">
                        <label>Signed By: {this.state.signed_by}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showSignModal && (
          <AddSignDialog
            fileId={this.state.fileId}
            userId={this.state.userId}
            closeModal={this.closeSignModal}
          />
        )}
        {this.state.showEditFileNameModal && (
          <EditFileNameDialog
            parentId={this.state.parentId}
            refreshList={this.updateList}
            idOfEditFileName={this.state.idOfEditFileName}
            closeModal={this.closeEditFileNameModal}
            fileName={this.state.fileName}
          />
        )}
      </>
    );
  }
}
const MapStateToProps = (state: any) => ({
  Comment: state.commentState.commentList,
  Parents: state.folderState.parentList,
  CurrentUser: state.loggedUserState.loggedInUser,
});

const MapDispatchToProps = (dispatch: any) => {
  return {
    GetCommentList: (id: string) => dispatch(GetCommentList(id)),
    GetParentHierarchy: (id: string) => dispatch(GetParentHierarchy(id)),
    GetLoggedInUser: () => dispatch(GetLoggedInUser()),
  };
};
export default withRouter(
  connect(MapStateToProps, MapDispatchToProps)(FileDetailPage)
);
