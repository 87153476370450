import { User, UserActions, UserActionsType } from "../types/users";

interface UserState {
    userList?: User[]
    user?: User
}

const initialState: UserState = {
    userList: undefined,
    user: undefined
}

const userReducer = (state: UserState = initialState, action: UserActionsType): UserState => {
    switch (action.type) {
        case UserActions.GET_USER_LIST:
            return {
                userList: action.users
            }
        case UserActions.GET_USER_BY_ID:
            return {
                user: action.user
            }
        default:
            return state;
    }
}
export default userReducer;