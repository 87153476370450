import { useState, useEffect } from 'react';
import * as React from 'react';
import { Button } from '../Button/Button';
import { FormInput } from '../Form/Form';
import { appAPI } from '../../../app/api-conn';
import { ModalDialog, ModalDialogContent, ModalDialogHeader } from '../ModalDialog/ModalDialog';

type AddNewFolderDialogProps = {
    parentId: string;
    closeModal: () => void;
    refreshList: () => void;
}

export const AddNewFolderDialog: React.FC<AddNewFolderDialogProps> = (props: AddNewFolderDialogProps) => {
    const [folderName, setFolderName] = useState<string>('');
    const [ApiError, setApiError] = useState<string>('');
    const [ApiResponse, setApiResponse] = useState<string>('');
    const [processing, setProcessing] = useState<Boolean>(false);

    useEffect(() => {
        setFolderName('');
        var element = document.getElementById("addFolder");
        element?.classList.add('in');
    }, []);

    const handleFolderName = (e: any) => {
        setFolderName(e.target.value);
    }
    const saveChanges = () => {
        setProcessing(true);
        setApiError('');
        setApiResponse('');

        if(folderName.length > 0){
            const inputObj = {
                "folder_name": folderName,
                "parent_id": parseInt(props.parentId)
            }
    
            // Call the API Method  
            const headers = {
                'Authorization': 'Bearer ' + localStorage.getItem("jwt")
            };
            appAPI.post('/client/add-folder', inputObj, { headers })
                .then((response:any) => {
                    if (response.status === 200 || response.status === 201) {
                        setFolderName('');
                        setApiResponse('Folder is added successfully');
                        setProcessing(false);
                        props.refreshList();
                    } else {
                        setApiError('Something is wrong !!'); 
                        setProcessing(false);
                    }
                }).catch((error:any) => {
                    setApiError(error.response.data.message);
                    setProcessing(false);
                })
        }else{
            setApiError('Please enter folder name.'); 
            setProcessing(false);
        }
    }

    return (
        <>
        <ModalDialog id="addFolder">
            <ModalDialogHeader title="Create Folder" closeButton={true} onClose={props.closeModal} />
            <ModalDialogContent>
                <div className="addusers-form-wrapper">
                    <span className="dialog-error">{ApiError}</span>
                    <h3 className="text-success">{ApiResponse}</h3>
                    <FormInput type="text" id="folderNameAdd" placeholder="Folder Name" value={folderName} onChange={handleFolderName} />
                    {!processing && <div className="form-row">
                        <Button id="save-add-user" onClick={saveChanges}>Create Folder</Button>
                    </div>}
                    {processing && <div className="col-md-12">
                        <div className="form-row">
                        <img src={`${process.env.PUBLIC_URL}/images/loading-buffering.gif`} alt="Loading" width={"20px"} height={"20px"}/>
                        </div>
                    </div>}
                </div>
            </ModalDialogContent>
        </ModalDialog>
        </>
    );
}
