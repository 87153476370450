import { useState, useEffect } from "react";
import * as React from "react";
import { Button } from "../Button/Button";
import { FormInput } from "../Form/Form";
import { appAPI } from "../../../app/api-conn";
import {
  ModalDialog,
  ModalDialogContent,
  ModalDialogHeader,
} from "../ModalDialog/ModalDialog";

type AddNewFolderDialogProps = {
  parentId: string;
  idOfEditFileName: number;
  fileName: string;
  closeModal: () => void;
  refreshList: () => void;
};

export const EditFileNameDialog: React.FC<AddNewFolderDialogProps> = (
  props: AddNewFolderDialogProps
) => {
  const [fileName, setFileName] = useState<string>(props.fileName);
  const [ApiError, setApiError] = useState<string>("");
  const [ApiResponse, setApiResponse] = useState<string>("");
  const [processing, setProcessing] = useState<Boolean>(false);

  useEffect(() => {
    var element = document.getElementById("addFolder");
    element?.classList.add("in");
  }, []);

  const handleFileName = (e: any) => {
    setFileName(e.target.value);
  };
  const saveChanges = () => {
    setProcessing(true);
    setApiError("");
    setApiResponse("");

    if (fileName.length > 0) {
      const inputObj = {
        object_name: fileName
      };

      // Call the API Method
      const headers = {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
      };
      appAPI
        .post(`/client/browsers/object/rename/${props.idOfEditFileName}`, inputObj, {
          headers,
        })
        .then((response:any) => {
          if (response.status === 200 || response.status === 201) {
            // setFileName("");
            setApiResponse("Renamed successfully");
            setProcessing(false);
            props.refreshList();
          } else {
            setApiError("Something is wrong !!");
            setProcessing(false);
          }
        })
        .catch((error:any) => {
          console.log("error message", error.response, typeof error);
          setApiError(error.Error);
          setProcessing(false);
        });
    } else {
      setApiError("Please enter name.");
      setProcessing(false);
    }
  };

  return (
    <>
      <ModalDialog id="addFolder">
        <ModalDialogHeader
          title="Edit Name"
          closeButton={true}
          onClose={props.closeModal}
        />
        <ModalDialogContent>
          <div className="addusers-form-wrapper">
            <span className="dialog-error">{ApiError}</span>
            <h3 className="text-success">{ApiResponse}</h3>
            <FormInput
              type="text"
              id="folderNameAdd"
              placeholder="New Name"
              value={fileName}
              onChange={handleFileName}
            />
            {!processing && (
              <div className="form-row">
                <Button id="save-add-user" onClick={saveChanges}>
                  Update Name
                </Button>
              </div>
            )}
            {processing && (
              <div className="col-md-12">
                <div className="form-row">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/loading-buffering.gif`}
                    alt="Loading"
                    width={"20px"}
                    height={"20px"}
                  />
                </div>
              </div>
            )}
          </div>
        </ModalDialogContent>
      </ModalDialog>
    </>
  );
};
