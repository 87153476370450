import { useState, useEffect } from 'react';
import * as React from 'react';
import { Button } from '../Button/Button';
import { FormInput } from '../Form/Form';
import { appAPI } from '../../../app/api-conn';
import { ModalDialog, ModalDialogContent, ModalDialogHeader } from '../ModalDialog/ModalDialog';

type AddSignDialogProps = {
    fileId?: string;
    userId: string;
    closeModal: () => void;
}

export const AddSignDialog: React.FC<AddSignDialogProps> = (props: AddSignDialogProps) => {
    const [showButtons, setShowButtons] = useState<Boolean>(true);
    const [ApiResponse, setApiResponse] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [ApiError, setApiError] = useState<string>('');
    const [processing, setProcessing] = useState<Boolean>(false);

    useEffect(() => {
        var element = document.getElementById("addSign");
        element?.classList.add('in');
    }, []);

    const confirmYes = async() => {
        setProcessing(true);
        if(password.length>0){
        if(props.userId && props.fileId){
            const inputObj = {
                "object_id": parseInt(props.fileId),
                "user_id": parseInt(props.userId),
                "password": password
            }
      
            // Call the API Method  
            const headers = {
                'Authorization': 'Bearer ' + localStorage.getItem("jwt")
            };
            const URL = `/client/browsers/object/add-sign`;
            await appAPI.post(URL, inputObj, { headers })
                .then((response:any) => {
                    if (response.status === 200 || response.status === 201) {
                        localStorage.setItem("Signed","true");
                        setShowButtons(false);
                        setApiResponse('Sign Added successfully');
                        setTimeout(() => {
                            props.closeModal();
                            document.body.classList.remove("modal-open");
                        }, 800);
                        setProcessing(false);
                        setApiError('');
                    } else {
                        console.log('Something is wrong !!');
                        setProcessing(false);
                    }
                }).catch((error:any) => {
                    setPassword('');
                    setProcessing(false);
                    setApiError(error.response.data.message);
                })
            }else{
                console.log('Details not fetched.');
                setProcessing(false);
            }
        }
        else{
            setApiError('Plaese enter your password.');
            setProcessing(false);
        }
    }

    const confirmNo = () => {
        localStorage.setItem("Signed","false");
        props.closeModal();
    }

    const handlePassword = (e:any) => {
        setPassword(e.target.value);
    }

    return (
        <>
        <ModalDialog id="addSign">
            <ModalDialogHeader title="Sign File Confirmation" closeButton={true} onClose={props.closeModal} />
            <ModalDialogContent>
                <div className="addusers-form-wrapper">
                <h3 className="text-success">{ApiResponse}</h3>
                    {showButtons && 
                    <>
                    <div className="form-row">
                        Would you like to acknowledge that the file presented is correct?
                        <FormInput type="password" id="password" placeholder="Password" value={password} onChange={handlePassword} />
                        <span className="dialog-error user-reg">{ApiError}</span>
                    </div>
                    {!processing && <div className="row">
                        <div className="col-md-6">
                            <div className="form-row">
                                <Button id="confirm-yes" onClick={confirmYes} disabled={false}>Sign File</Button>
                            </div>
                        </div>
                        <div className="col-md-6">    
                            <div className="form-row">
                                <Button id="confirm-no" onClick={confirmNo} disabled={false}>Cancel</Button>
                            </div>
                        </div>
                    </div>}
                    </>}
                    {processing && <div className="col-md-12">
                        <div className="form-row">
                        <img src={`${process.env.PUBLIC_URL}/images/loading-buffering.gif`} alt="Loading" width={"20px"} height={"20px"}/>
                        </div>
                    </div>}
                </div>
            </ModalDialogContent>
        </ModalDialog>
        </>
    );
}
