import * as React from 'react';
import { Link } from 'react-router-dom';
import { UserInfo } from '../UserInfo/UserInfo';

type SideNavigationProps = {}

export const SideNavigation: React.FC<React.PropsWithChildren<SideNavigationProps>> = (props: React.PropsWithChildren<SideNavigationProps>) => {
  function logoutUser(){
    localStorage.clear();
  }
  const [canNavigate, setCanNavigate] = React.useState(false);

  React.useEffect(() => {
    // fetching name from storage to display on user profile
    const fetchedUser = JSON.parse(localStorage.getItem("userPref")+"");
    if (fetchedUser && [4,5].includes(fetchedUser?.user_type_id || 0)) {
      setCanNavigate(false);
    } else {
      setCanNavigate(true);
    }
  }, []);

  return(
    <div className="col-md-4 col-lg-3 sidebar-container">
      <div className="row">
        <div className="sidebar-section">
          <UserInfo />
          <ul>
            <li><Link to="/"><i className="icon-col files-folder-icon"></i> <span>Files and Folders</span></Link></li>
            {canNavigate && <li><Link to="/users"><i className="icon-col users-group-icon"></i> <span>Users and Groups</span></Link></li>}
            <li><Link to="/change-password"><i className="icon-col change-password-icon"></i> <span>Change Password</span></Link></li>
            <li><Link to="/auth/login" onClick={logoutUser}><i className="icon-col log-out-icon"></i> <span>Log Out</span></Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
}
