import * as React from 'react';
import { Redirect, Route, useHistory } from "react-router-dom";
import { isLoggedIn } from '../../../app/api-conn';

type PrivateRouteProps = {
  component: React.ComponentClass | any;
  path: string;
  exact?: boolean;
}
const parseJwt = (token:any) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
const PrivateRoute: React.FC<React.PropsWithChildren<PrivateRouteProps>> = (props: React.PropsWithChildren<PrivateRouteProps>) => {
  const history = useHistory();
  if(localStorage.getItem("jwt") !== ''){
    const decodedJwt = parseJwt(localStorage.getItem("jwt"));
    if(decodedJwt){
    if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.clear();
        window.location.reload();
        history.push("/auth/login");
      }
    }
  }

  return isLoggedIn() ? (<Route path={props.path} exact={props.exact} render = {routerProps => < props.component />} />) : (<Redirect to="/auth/login" />);
}

export default PrivateRoute;