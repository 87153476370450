import { Dispatch } from "redux";
import { appAPI } from "../api-conn";
import { UserActions, UserActionsType } from "../types/users";

export const GetUserList = (clientId: string) => async (dispatch: Dispatch<UserActionsType>) => {
    const URL = `/auth/client/${clientId}`;
    await appAPI.get(
        URL,
        { headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwt') } }
    ).then((res: any) => {
        dispatch({
            type: UserActions.GET_USER_LIST,
            users: res.data.list
        });
    }).catch((error: any) => {

    });
}

export const GetUserById = (userId: string) => async (dispatch: Dispatch<UserActionsType>) => {
    const URL = `/auth/user/details/${userId}`;
    await appAPI.get(
        URL,
        { headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwt') } }
    ).then((res: any) => {
        dispatch({
            type: UserActions.GET_USER_BY_ID,
            user: res.data.detail
        });
    }).catch((error: any) => {

    });
}