import * as React from 'react';
import { useState } from 'react';
import { LoginFormInput } from '../../components/Form/Form';
import { LoginButton, LinkButton } from '../../components/Button/Button';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoginRequest, LoginResponse, UserActions } from '../../../app/types';
import { appAPI } from '../../../app/api-conn';
import validator from 'validator';

type LoginPageProps = {
}

export const LoginPage:React.FC<LoginPageProps> = (props: LoginPageProps) => {
  // Set the Dispatcher
  const dispatch = useDispatch();

  // Set the Browser History
  const history = useHistory();
 
  // Set the States
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [error, setError] = useState<string>();
 
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {    
    event.preventDefault();
    setEmailError('');
    setError('');

    let inputError = false;
    if( !validator.isEmail(email) ){
      inputError = true;
      setEmailError('Please enter valid email address');
    }
    if(password.length < 1){
      inputError = true;
      setError('Please enter password');
    }

    if(!inputError){
      // Build the Request
      let credentials: LoginRequest = {
        email: email.toLowerCase(),
        password: password
      }
  
      // Call the API Method
      appAPI.post('/auth/login', credentials).then((res:any) => {
        // Get the Login Response
        let loginResponse: LoginResponse = res.data;

        // Set the JWT
        localStorage.setItem("jwt", loginResponse.jwt);
        // Set the Refresh Token
        localStorage.setItem("refresh", loginResponse.refresh);
        // using local storage to persist user data to prevent
        localStorage.setItem("userPref", JSON.stringify(loginResponse.user));

        // Add the Current User
        dispatch({
          type: UserActions.SET_LOGGED_IN_USER,
          user: loginResponse.user
        });
        history.push("/dashboard");
        
      }).catch((error:any) => {
        // Get the Error Object
        let apiError: any = error?.response?.data
        setError(apiError?.message);
      });
    }
  }
   
  const handleEmail = (e:any) => {   
    setEmail(e.target.value);
  }
   
  const handlePassword = (e:any) => {    
    setPassword(e.target.value);
  }
  
  return(
    <section className="login-page-section">
      <div className="container">
        <div className="row">
          <div className="login-section">
            <div className="login-header"><img src={`${process.env.PUBLIC_URL}/images/login-page-logo.png`} alt="valcomm-logo" /></div>
            <div className="login-content-section">
              <div className="login-content-title">Login to your account</div>
                <div className="login-form-wrapper">
                  <form onSubmit={handleSubmit} method="POST">
                    <LoginFormInput type="text" id="email" placeholder="Email Address" autoComplete={false} icon="email-address-icon" onChange={handleEmail} />
                    <span className="dialog-error login-error">{emailError}</span>
                    <LoginFormInput type="password" id="password" placeholder="Password" autoComplete={false} icon="password-input-icon" onChange={handlePassword} />
                    <span className="dialog-error login-error">{error}</span>
                    <LoginButton id="login" value={'Sign In'} />
                  </form>
                  <LinkButton to="/auth/forgot-password" class="forget-password-text" value={'Forgotten Password?'} />           
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}