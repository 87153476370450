export type User = {
    id: number | string;
    email: string;
    first_name: string;
    last_name: string;
    client_id?: number;
    user_type_id?: number;
    user_type_name?: string;
    user_type_tag?: string;
    is_active?: number;
}

export enum UserActions {
    GET_USER_LIST = "GET_USER_LIST",
    GET_USER_BY_ID = "GET_USER_BY_ID"
}

export interface GetUserList {
    type: typeof UserActions.GET_USER_LIST,
    users: User[]
}

export interface GetUserById {
    type: typeof UserActions.GET_USER_BY_ID,
    user: User
}

export type UserActionsType = GetUserList | GetUserById;