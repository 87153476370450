import { useState, useEffect } from 'react';
import * as React from 'react';
import { ModalDialog, ModalDialogContent, ModalDialogHeader } from '../ModalDialog/ModalDialog';
import Dropzone from 'react-dropzone-uploader';

type UploadFileDialogProps = {
    closeModal: () => void;
    refreshList: () => void;
    parentId: string;
    clientId?:string;
}
export const UploadFileDialog: React.FC<UploadFileDialogProps> = (props: UploadFileDialogProps) => {
    const [ApiError, setApiError] = useState<string>('');
    const [ApiResponse, setApiResponse] = useState<string>('');

    useEffect(() => {
        var element = document.getElementById("uploadFiles");
        element?.classList.add('in');
        setApiError('');
        setApiResponse('');
    }, []);

    const getUploadParams = ({ file, meta }: any) => {
        const url = process.env.REACT_APP_API_URL + '/client/upload-files'
        
        const body = new FormData()
        body.append('parent_id', props.parentId)
        body.append('file', file)

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("jwt")
        };
        return { url, headers, body }
    }

    const handleChangeStatus = ({ meta, remove  }: any, status: any) => {
        if (status === 'done') {
            localStorage.setItem("Files Added","true");
            setApiResponse(`${meta.name} uploaded successfully`);
            props.refreshList();
        } else if (status === 'aborted') {
            setApiError(`${meta.name}, Upload failed...`);
        }
    }

    const handleSubmit = (files: any[], allFiles: any[]) => {
        allFiles.forEach(f => f.remove())
    }

    return (
        <>
        <ModalDialog id="uploadFiles">
            <ModalDialogHeader title="Upload New Files" closeButton={true} onClose={props.closeModal} />
            <ModalDialogContent>
                <div className="addusers-form-wrapper dropfile-popup">
                    <span className="dialog-error">{ApiError}</span>
                    <h3 className="text-success">{ApiResponse}</h3>
                    <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                        inputWithFilesContent="+"
                        submitButtonContent="Upload Files"
                        canRemove={true}
                        inputContent="Drag Files or Click to Upload File(s)"
                        styles={{
                            dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                            inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
                        }}
                    />
                </div>
            </ModalDialogContent>
        </ModalDialog>
        </>
    );
}
