import { Dispatch } from "redux";
import { appAPI } from "../api-conn";
import { FolderActions, FolderActionsType } from "../types/folder";

export const GetFolderList = (parentId: string) => async (dispatch: Dispatch<FolderActionsType>) => {

    const URL = `/client/browsers/` + parentId;

    await appAPI.get(
        URL,
        { headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwt') } }
    ).then((res: any) => {
        dispatch({
            type: FolderActions.GET_FOLDER_LIST,
            folders: res.data.data
        });
    }).catch((error: any) => {

    });
}

export const GetFolderHierarchy = (parentId: string) => async (dispatch: Dispatch<FolderActionsType>) => {

    const URL = `/client/get-object-hierarchy/` + parentId;

    await appAPI.get(
        URL,
        { headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwt') } }
    ).then((res: any) => {
        dispatch({
            type: FolderActions.GET_FOLDER_HIERARCHY,
            folders: res.data.data
        });
    }).catch((error: any) => {

    });
}

export const GetParentHierarchy = (objectId: string) => async (dispatch: Dispatch<FolderActionsType>) => {

    const URL = `/client/get-parent-breadcrumb-for-object/` + objectId;

    await appAPI.get(
        URL,
        { headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwt') } }
    ).then((res: any) => {
        dispatch({
            type: FolderActions.GET_PARENT_HIERARCHY,
            folders: res.data.data
        });
    }).catch((error: any) => {

    });
}

export const SearchObjects = (keyword: string) => async (dispatch: Dispatch<FolderActionsType>) => {

    const URL = `/client/browsers/search/` + keyword;

    await appAPI.get(
        URL,
        { headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwt') } }
    ).then((res: any) => {
        dispatch({
            type: FolderActions.SEARCH_OBJECTS,
            folders: res.data.data
        });
    }).catch((error: any) => {

    });
}