import { useState, useEffect } from 'react';
import * as React from 'react';
import validator from 'validator';
import { Button } from '../Button/Button';
import { FormInput } from '../Form/Form';
import { appAPI } from '../../../app/api-conn';
import { ModalDialog, ModalDialogContent, ModalDialogHeader } from '../ModalDialog/ModalDialog';

type RegisterUserDialogProps = {
    closeModal: () => void;
    refreshList: () => void;
}

export const RegisterUserDialog: React.FC<RegisterUserDialogProps> = (props: RegisterUserDialogProps) => {
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [ApiResponse, setApiResponse] = useState<string>('');
    const [fnameError, setFnameError] = useState<string>('');
    const [lnameError, setLnameError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [processing, setProcessing] = useState<Boolean>(false);

    useEffect(() => {
        setFirstName('');
        setLastName('');
        setEmail('');
        var element = document.getElementById("addUser");
        element?.classList.add('in');
    }, []);

    const handleFirstName = (e: any) => {
        setFirstName(e.target.value);
    }
    const handleLastName = (e: any) => {
        setLastName(e.target.value);
    }
    const handleEmail = (e: any) => {
        setEmail(e.target.value);
    }
    const saveChanges = () => {
        setApiResponse('');
        setFnameError('');
        setLnameError('');
        setEmailError('');

        let inputError = false;
        if(firstName.length < 1){
            inputError = true;
            setFnameError('Please enter first name');
        }
        if(lastName.length < 1){
            inputError = true;
            setLnameError('Please enter last name');
        }
        if( !validator.isEmail(email) ){
            inputError = true;
            setEmailError('Please enter valid email address');
        }

        if(!inputError){
            setProcessing(true);

            const inputObj = {
                "client_id": 0,
                "email": email,
                "first_name": firstName,
                "last_name": lastName,
                "user_type_id": 1,
                "user_type_name": "Super Admin",
                "user_type_tag": "super-admin"
            }
    
            // Call the API Method  
            const headers = {
                'Authorization': 'Bearer ' + localStorage.getItem("jwt")
            };
            appAPI.post('/auth/add-user', inputObj, { headers })
                .then((response:any) => {
                    if (response.status === 200 || response.status === 201) {
                        setProcessing(false);
                        props.refreshList();
                        setFirstName('');
                        setLastName('');
                        setEmail('');
                        setApiResponse('User is added successfully');
                    } else {
                        setEmailError('Something is wrong !!'); 
                    }
                }).catch((error:any) => {
                    setEmailError(error.response.data.message);
                    setProcessing(false);
                })
        }
    }

    return (
        <>
        <ModalDialog id="addUser">
            <ModalDialogHeader title="Register Staff Users" closeButton={true} onClose={props.closeModal} />
            <ModalDialogContent>
                <div className="addusers-form-wrapper">
                    <h3 className="text-success">{ApiResponse}</h3>
                    <FormInput type="text" id="registerFirstName" placeholder="First Name" value={firstName} onChange={handleFirstName} />
                    <span className="dialog-error user-reg">{fnameError}</span>
                    <FormInput type="text" id="registerLastName" placeholder="Last Name" value={lastName} onChange={handleLastName} />
                    <span className="dialog-error user-reg">{lnameError}</span>
                    <FormInput type="text" id="registerEmail" placeholder="Email Address" value={email} onChange={handleEmail} />
                    <span className="dialog-error user-reg user-reg">{emailError}</span>
                    {!processing && <div className="form-row">
                        <Button id="save-register-user" onClick={saveChanges}>Register Staff User</Button>
                    </div>}
                    {processing && <div className="col-md-12">
                        <div className="form-row">
                        <img src={`${process.env.PUBLIC_URL}/images/loading-buffering.gif`} alt="Loading" width={"20px"} height={"20px"}/>
                        </div>
                    </div>}
                </div>
            </ModalDialogContent>
        </ModalDialog>
        </>
    );
}
