import { Client, ClientActions, ClientActionsType } from "../types/client";

interface ClientState {
    clientList?: Client[]
}

const initialState: ClientState = {
    clientList: undefined
}

const clientReducer = (state: ClientState = initialState, action: ClientActionsType): ClientState => {
    switch (action.type) {
        case ClientActions.GET_CLIENT_LIST:
            return {
                clientList: action.clients
            }
        default:
            return state;
    }
}
export default clientReducer;