import * as React from 'react';
import {connect} from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {GetUserList, GetUserById} from "../../../app/actions/users";
import {GetLoggedInUser} from "../../../app/actions/user";
import { appAPI } from '../../../app/api-conn';
import {User} from "../../../app/types/users";
import { Delete } from '../../components/ModalDialog/Delete';
import { RegisterUserDialog } from '../../components/ModalDialog/RegisterUser';
import { EditUserDialog } from "../../components/ModalDialog/EditUser"
import LoadingOverlay from 'react-loading-overlay';
import styled, { css } from "styled-components";
import AuthVerifyOnClick from '../../../common/AuthVerifyOnClick';

type StaffProps = {
  GetUserList(clientId:string): [],
  GetUserById(userId:string): User,
  GetLoggedInUser(): [],
  User: User,
  CurrentUser:User
}

const DarkBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  ${() =>
    css`
      display: block;
      position: fixed;
    `}
`;

class Staff extends React.Component<StaffProps> {
  state = {
    clientId: "0",
    userId: 0,
    userList: [],
    fullList: [],
    redirect: null,
    todos: [{ id: ""}],
    showRegisterModal: false,
    showEditModal: false,
    showDropdown: false,
    showDeleteModal: false,
    loadingOverlay: false
  }

  componentDidMount = async() => {
    document.body.classList.remove('login-bg');

    await this.props.GetLoggedInUser();
    if(this.props.CurrentUser?.user_type_id !== 1){
      this.setState({ redirect: "/users" });
    }

    await this.props.GetUserList(this.state.clientId.toString());
    this.setState({userList: this.props.User, fullList: this.props.User});
    localStorage.setItem('Delete','undefined');
  }
  componentDidUpdate = () => {
    var confirmDelete = localStorage.getItem('Delete');
    if(confirmDelete==='true'){
      if(this.state.loadingOverlay===false){
        this.setState({loadingOverlay:true});
      }
      // Call the API Method  
      const headers = {
        'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      };

      for(let item=1;item<this.state.todos.length;item++){
        const URL = `/auth/delete-user/` + this.state.todos[item]['id'];

        appAPI.delete(URL, { headers })
          .then((response:any) => {
            if (response.status === 200 || response.status === 201) {
              this.updateUserList();
              localStorage.setItem('Delete','undefined');
              this.setState({loadingOverlay:false});
              console.log('Client Deleted successfully');
            } else {
              console.log('Something is wrong !!'); 
            }
          }).catch((error:any) => {
            console.log("Error: "+error?.response?.data?.message);
          })
      }
    }
  }

  updateUserList = async() => {
    AuthVerifyOnClick();
    await this.props.GetUserList(this.state.clientId.toString());
    this.setState({userList: this.props.User, fullList: this.props.User});
    setTimeout(() => {
      document.body.classList.remove("modal-open");
      this.setState({ showRegisterModal: false, showEditModal: false });
    }, 800);
  }
  toggleDropDownButton = () => {
    AuthVerifyOnClick();
    this.setState({showDropdown: !this.state.showDropdown});
  }
  deleteSelected = () => {
    this.setState({showDropdown: !this.state.showDropdown});
    if(this.state?.todos?.length > 1 && this.state?.todos[1]?.id !== ''){
      document.body.classList.add('modal-open');
      this.setState({showDeleteModal: true});
    }
  }
  registerUserClick = () => {
    AuthVerifyOnClick();
    document.body.classList.add('modal-open');
    this.setState({showRegisterModal: true});
  }
  closeRegisterModal = () => {
    AuthVerifyOnClick();
    this.setState({showRegisterModal: false});
  }
  closeDeleteModal = () => {
    AuthVerifyOnClick();
    this.setState({showDeleteModal: false});
  }
  handleCheckBox = (e:any) => {
    const { name, value, checked } = e.target;

    //collect data individual click/check
    if(checked && name !== "select_all"){
      let todos = [...this.state.todos];
      todos.push({
        id: e.target.value
      });
      this.setState({todos});
    }else if(name !== "select_all"){
      let todos = [...this.state.todos];
      let newList = todos.filter((user) => user.id !== value);
      this.setState({todos:newList});
    }

    //collect data for select all click/check
    if(checked && name === "select_all"){
      let todos = [...this.state.todos];
      this.state.userList.map((user:User) => {
        todos.push({
          id: user.id.toString()
        });
        return { ...user, isChecked: checked };
      });
      this.setState({todos});
    }else if(!checked && name === "select_all"){
      this.setState({todos:[{ id: ""}]});
    }

    // Manage/Sync Check and Uncheck boxes on click
    if (name === "select_all") {
      let tempUser = this.state.userList.map((user:User) => {
        return { ...user, isChecked: checked };
      });
      this.setState({userList:tempUser});
    } else {
      let tempUser = this.state.userList.map((user:User) =>
        user.id.toString() === value ? { ...user, isChecked: checked } : user
      );
      this.setState({userList:tempUser});
    }
  }
  isCheckedSelectAll = () => {
    if(this.state.userList?.length < 1){
      return false;
    }
    if(!this.state.userList?.some((user:any) => user?.isChecked !== true)){
      return true;
    }
    return false;
  }
  editUser = async (user_id: number) => {
    AuthVerifyOnClick();
    this.setState({ userId: user_id });
    await this.props.GetUserById(user_id.toString());
    document.body.classList.add("modal-open");
    this.setState({ showEditModal: true });
  };
  closeEditUserModal = () => {
    AuthVerifyOnClick();
    document.body.classList.remove("modal-open");
    this.setState({ showEditModal: false });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return(
      <>
      <div className="row">
        <div className="dashboard-content-section">
          
          <div className="dashboard-header-section">
            <h2>Manage Users - <span className="current">VALCOMM Staff</span></h2>

            <div className="dashboard-search-section">
              <div className="row">
                <div className="col-md-5">
                  <div className="search-section-back">
                    <Link to="/users">
                      <button type="button" name="create-button">Go Back</button>
                    </Link>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="search-section-button">
                    <ul>
                      <li><button type="button" name="create-button" onClick={this.registerUserClick}>Register Users</button></li>
                      <li>
                        <div className="btn-group">
                          <button type="button" className="btn btn-primary dropdown-toggle" onClick={this.toggleDropDownButton}>
                            Action
                          </button>
                          <div className={`dropdown-menu ${this.state.showDropdown ? 'show' : ''}`}>
                            <ul>
                              <li>
                                <div className="dropdown-item delete" onClick={this.deleteSelected}>
                                  Delete Selected <i className="fa fa-trash" aria-hidden="true"></i>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="dashboard-data-section">
            <div className="table-responsive-xl">
              <table width="100%" className="table">
                <thead>
                  <tr>
                    <th>
                      <input type="checkbox" name="select_all" 
                        checked={this.isCheckedSelectAll()} 
                        onChange={e => this.handleCheckBox(e)}/>
                    </th>
                    <th>Last Name</th>
                    <th>First Name</th>
                    <th>Email</th>
                    <th>User Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  { this.state?.userList?.map((user, index) => {
                  return(
                  <tr className={index % 2 === 0 ? `odd` : `even`} key={user['id']}>
                    <td>
                        <input type="checkbox" name={`user${user['id']}`} value={user['id']} 
                          checked={user['isChecked'] || false} 
                          onChange={e => this.handleCheckBox(e)}/>
                    </td>
                    <td>{user['last_name']}</td>
                    <td>{user['first_name']}</td>
                    <td>{user['email']}</td>
                    <td>{user['user_type_name']}</td>
                    <td>
                      <button
                        type="button"
                        className={`btn margin-left margin-bottom deactive-button`}
                        onClick={() => this.editUser(user["id"])}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                  )
                  })}

                  {this.state.userList?.length === 0  && <tr className="even">
                    <td colSpan={4} className="not-available">No Data Available</td>
                  </tr>}
                </tbody>
              </table>
            </div>
        </div>
        </div>
      </div>
      {
        this.state.showRegisterModal && <RegisterUserDialog refreshList={this.updateUserList} closeModal={this.closeRegisterModal} />
      }
      {this.state.showEditModal && (
        <EditUserDialog
          refreshList={this.updateUserList}
          userId={this.state.userId}
          closeModal={this.closeEditUserModal}
        />
      )}
      {
        this.state.showDeleteModal && <Delete closeModal={this.closeDeleteModal} />
      }
      {
        this.state.loadingOverlay && <DarkBackground>
          <div style={{"position":"fixed","top":"50%","left":"50%"}}>
            <LoadingOverlay 
              active={true}
              spinner={true}
              text="Processing..."
            >
            </LoadingOverlay>
          </div>
        </DarkBackground>
      }
      </>
    );
  }
}

const MapStateToProps = (state: any) => ({
  User: state.userState.userList,
  CurrentUser: state.loggedUserState.loggedInUser,
});

const MapDispatchToProps = (dispatch:any) =>{
  return {
    GetUserList: (id:string) => dispatch(GetUserList(id)),
    GetLoggedInUser: () => dispatch(GetLoggedInUser()),
    GetUserById: (id: string) => dispatch(GetUserById(id))
  };
};
  
  export default connect(MapStateToProps, MapDispatchToProps) (Staff);