import * as React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { appAPI } from "../../../app/api-conn";
import { GetUserList, GetUserById } from "../../../app/actions/users";
import { GetLoggedInUser } from "../../../app/actions/user";
import { User } from "../../../app/types/users";
import { Delete } from "../../components/ModalDialog/Delete";
import { withRouter, RouteComponentProps } from "react-router";
import { EditUserClientDialog } from "../../components/ModalDialog/EditUserClient";
import { RegisterClientDialog } from "../../components/ModalDialog/RegisterClient";
import FolderAccessDialog from "../../components/ModalDialog/FolderAccess";
import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";
import AuthVerifyOnClick from "../../../common/AuthVerifyOnClick";

type ClientProps = {
  GetUserList(clientId: string): [],
  GetUserById(userId:string): User,
  GetLoggedInUser(): [],
  User: User,
  CurrentUser: User
};

const DarkBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  ${() =>
    css`
      display: block;
      position: fixed;
    `}
`;

class Client extends React.Component<ClientProps & RouteComponentProps<any>> {
  state = {
    clientId: this.props?.match?.params?.id!,
    userId: 0,
    objectId: "",
    clientName: "",
    userList: [],
    fullList: [],
    redirect: null,
    loggedUserId: 0,
    loggedUsertype: 0,
    canDoAction: false,
    canManageFolder: false,
    todos: [{ id: "" }],
    showRegisterModal: false,
    showDropdown: false,
    showEditModal: false,
    showDeleteModal: false,
    showFolderAccessModal: false,
    loadingOverlay: false,
  };

  componentDidMount = async () => {
    document.body.classList.remove("login-bg");

    await this.props.GetLoggedInUser();
    this.setState({ loggedUserId: this.props.CurrentUser?.id });
    this.setState({ loggedUsertype: this.props.CurrentUser?.user_type_id });

    let userTypeId = this.props.CurrentUser?.user_type_id || 0;
    if (
      [4,5].includes(userTypeId)
    ) {
      this.setState({ redirect: "/" });
    }
    else if (
      this.props.CurrentUser?.user_type_id !== 1 &&
      this.props.CurrentUser?.client_id?.toString() !== this.state.clientId
    ) {
      this.setState({ redirect: "/users" });
    }
    this.isAllowed();

    await this.props.GetUserList(this.state.clientId.toString());
    if (this.props.User ){
      this.setState({ userList: this.props.User, fullList: this.props.User });
    } else {
      this.setState({ userList: [], fullList: [] });
    }
    
    this.getObjectId();
    localStorage.setItem("Delete", "undefined");
  };
  componentDidUpdate = () => {
    var confirmDelete = localStorage.getItem("Delete");
    if (confirmDelete === "true") {
      if (this.state.loadingOverlay === false) {
        this.setState({ loadingOverlay: true });
      }
      // Call the API Method
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      };
      for (let item = 1; item < this.state.todos.length; item++) {
        const URL = `/auth/delete-user/` + this.state.todos[item]["id"];

        appAPI
          .delete(URL, { headers })
          .then((response:any) => {
            if (response.status === 200 || response.status === 201) {
              this.updateUserList();
              localStorage.setItem("Delete", "undefined");
              this.setState({ loadingOverlay: false });
              console.log("Client Deleted successfully");
            } else {
              console.log("Something is wrong !!");
            }
          })
          .catch((error:any) => {
            console.log("Error: " + error?.response?.data?.message);
          });
      }
    }
  };

  getObjectId = async () => {
    const URL = `/client/details/` + this.state.clientId;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    };
    appAPI
      .get(URL, { headers })
      .then((response:any) => {
        if (response.status === 200 || response.status === 201) {
          let data = response?.data?.detail;
          this.setState({ objectId: data?.object_id, clientName: data?.name });
        } else {
          console.log("Something is wrong !!");
        }
      })
      .catch((error:any) => {
        console.log("Error: " + error?.response?.data?.message);
      });
  };

  updateUserList = async () => {
    AuthVerifyOnClick();
    await this.props.GetUserList(this.state.clientId.toString());
    if (this.props.User) {
      this.setState({ userList: this.props.User, fullList: this.props.User });
    } else {
      this.setState({ userList: [], fullList: [] });
    }
    
    setTimeout(() => {
      document.body.classList.remove("modal-open");
      this.setState({ showRegisterModal: false, showEditModal: false });
    }, 800);
  };
  toggleDropDownButton = () => {
    AuthVerifyOnClick();
    this.setState({ showDropdown: !this.state.showDropdown });
  };
  deleteSelected = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
    if (this.state?.todos?.length > 1 && this.state?.todos[1]?.id !== "") {
      document.body.classList.add("modal-open");
      this.setState({ showDeleteModal: true });
    }
  };
  folderAccessClick = (user_id: number) => {
    AuthVerifyOnClick();
    this.setState({ userId: user_id });
    document.body.classList.add("modal-open");
    this.setState({ showFolderAccessModal: true });
  };
  registerClientClick = () => {
    AuthVerifyOnClick();
    document.body.classList.add("modal-open");
    this.setState({ showRegisterModal: true });
  };
  closeRegisterModal = () => {
    AuthVerifyOnClick();
    this.setState({ showRegisterModal: false });
  };
  closeDeleteModal = () => {
    AuthVerifyOnClick();
    this.setState({ showDeleteModal: false });
  };
  closeFolderAccessModal = () => {
    AuthVerifyOnClick();
    this.setState({ showFolderAccessModal: false });
  };
  handleCheckBox = (e: any) => {
    AuthVerifyOnClick();
    const { name, value, checked } = e.target;

    //collect data individual click/check
    if (checked && name !== "select_all") {
      let todos = [...this.state.todos];
      todos.push({
        id: e.target.value,
      });
      this.setState({ todos });
    } else if (name !== "select_all") {
      let todos = [...this.state.todos];
      let newList = todos.filter((user) => user.id !== value);
      this.setState({ todos: newList });
    }

    //collect data for select all click/check
    if (checked && name === "select_all") {
      let todos = [...this.state.todos];
      this.state.userList.map((user: User) => {
        todos.push({
          id: user.id.toString(),
        });
        return { ...user, isChecked: checked };
      });
      this.setState({ todos });
    } else if (!checked && name === "select_all") {
      this.setState({ todos: [{ id: "" }] });
    }

    // Manage/Sync Check and Uncheck boxes on click
    if (name === "select_all") {
      let tempUser = this.state.userList.map((user: User) => {
        return { ...user, isChecked: checked };
      });
      this.setState({ userList: tempUser });
    } else {
      let tempUser = this.state.userList.map((user: User) =>
        user.id.toString() === value ? { ...user, isChecked: checked } : user
      );
      this.setState({ userList: tempUser });
    }
  };
  isCheckedSelectAll = () => {
    if (this.state.userList?.length < 1) {
      return false;
    }
    if (!this.state.userList?.some((user: any) => user?.isChecked !== true)) {
      return true;
    }
    return false;
  };
  changeStatus = (user: User) => {
    AuthVerifyOnClick();
    // Call the API Method
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    };
    const inputObj = {
      user_id: user.id,
      is_active: user.is_active === 1 ? 0 : 1,
    };

    appAPI
      .post("/auth/update-status", inputObj, { headers })
      .then((response:any) => {
        if (response.status === 200 || response.status === 201) {
          this.updateUserList();
        } else {
          console.log("Something is wrong !!");
        }
      })
      .catch((error:any) => {
        console.log("Error: " + error?.response?.data?.message);
      });
  };
  isAllowed = () => {
    if ([1].includes(this.state.loggedUsertype)) {
      this.setState({ canDoAction: true });
    } else {
      this.setState({ canDoAction: false });
    }
    if ([1, 2].includes(this.state.loggedUsertype)) {
      this.setState({ canManageFolder: true });
    } else {
      this.setState({ canManageFolder: false });
    }
  };
  editUser = async (user_id: number) => {
    AuthVerifyOnClick();
    this.setState({ userId: user_id });
    await this.props.GetUserById(user_id.toString());
    document.body.classList.add("modal-open");
    this.setState({ showEditModal: true });
  };
  closeEditUserModal = () => {
    AuthVerifyOnClick();
    document.body.classList.remove("modal-open");
    this.setState({ showEditModal: false });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <div className="row">
          <div className="dashboard-content-section">
            <div className="dashboard-header-section">
              <h2>
                Manage Users -{" "}
                <span className="current">{this.state.clientName}</span>
              </h2>

              <div className="dashboard-search-section">
                <div className="row">
                  <div className="col-md-5">
                    <div className="search-section-back">
                      <Link to="/users">
                        <button type="button" name="create-button">Go Back</button>
                      </Link>
                    </div>
                  </div>
                  {this.state.canDoAction && (
                    <div className="col-md-7">
                      <div className="search-section-button">
                        <ul>
                          <li>
                            <button
                              type="button"
                              name="create-button"
                              onClick={this.registerClientClick}
                            >
                              Add Users
                            </button>
                          </li>
                          <li>
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-primary dropdown-toggle"
                                onClick={this.toggleDropDownButton}
                              >
                                Action
                              </button>
                              <div
                                className={`dropdown-menu ${
                                  this.state.showDropdown ? "show" : ""
                                }`}
                              >
                                <ul>
                                  <li>
                                    <div
                                      className="dropdown-item delete"
                                      onClick={this.deleteSelected}
                                    >
                                      Delete Selected <i className="fa fa-trash" aria-hidden="true"></i>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="dashboard-data-section">
              <div className="table-responsive-xl">
                <table width="100%" className="table">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          name="select_all"
                          checked={this.isCheckedSelectAll()}
                          onChange={(e) => this.handleCheckBox(e)}
                        />
                      </th>
                      <th>Last Name</th>
                      <th>First Name</th>
                      <th>Email</th>
                      <th>User Type</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state?.userList?.map((user, index) => {
                      return (
                        <tr
                          className={index % 2 === 0 ? `odd` : `even`}
                          key={user["id"]}
                        >
                          <td>
                            <input
                              type="checkbox"
                              name={`user${user["id"]}`}
                              value={user["id"]}
                              checked={user["isChecked"] || false}
                              onChange={(e) => this.handleCheckBox(e)}
                            />
                          </td>
                          <td>{user["last_name"]}</td>
                          <td>{user["first_name"]}</td>
                          <td>{user["email"]}</td>
                          <td>{user["user_type_name"]}</td>
                          <td>
                            {this.state.canDoAction &&
                              this.state.loggedUsertype <=
                                user["user_type_id"] && 
                                this.state.loggedUserId !== user["id"] && (
                                <>
                                  <button
                                    type="button"
                                    className={`btn margin-left margin-bottom deactive-button`}
                                    onClick={() => this.editUser(user["id"])}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    type="button"
                                    className={`btn margin-left margin-bottom deactive-button`}
                                    onClick={() => this.changeStatus(user)}
                                  >
                                    {user["is_active"] === 1
                                      ? "Deactivate"
                                      : "Activate"}
                                  </button>
                                </>
                              )}
                              {this.state.canManageFolder && 
                              [4, 5].includes(user["user_type_id"]) && (
                                <>
                                  {user["user_type_id"] !== 2 &&
                                    user["user_type_id"] !== 3 && (
                                      <button
                                        type="button"
                                        className="btn margin-bottom manage-folder-button"
                                        onClick={() =>
                                          this.folderAccessClick(user["id"])
                                        }
                                      >
                                        Manage Folder Access
                                      </button>
                                    )}
                                </>
                              )}
                          </td>
                        </tr>
                      );
                    })}

                    {this.state.userList?.length === 0 && (
                      <tr className="even">
                        <td colSpan={6} className="not-available">
                          No Data Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {this.state.canDoAction && this.state.showRegisterModal && (
          <RegisterClientDialog
            refreshList={this.updateUserList}
            clientId={this.state.clientId}
            closeModal={this.closeRegisterModal}
          />
        )}
        {this.state.canDoAction && this.state.showEditModal && (
          <EditUserClientDialog
            refreshList={this.updateUserList}
            userId={this.state.userId}
            closeModal={this.closeEditUserModal}
          />
        )}
        {this.state.canDoAction && this.state.showDeleteModal && (
          <Delete closeModal={this.closeDeleteModal} />
        )}
        {this.state.canManageFolder && this.state.showFolderAccessModal && (
          <FolderAccessDialog
            objectId={this.state.objectId}
            userId={this.state.userId}
            closeModal={this.closeFolderAccessModal}
          />
        )}
        {this.state.loadingOverlay && (
          <DarkBackground>
            <div style={{ position: "fixed", top: "50%", left: "50%" }}>
              <LoadingOverlay
                active={true}
                spinner={true}
                text="Processing..."
              ></LoadingOverlay>
            </div>
          </DarkBackground>
        )}
      </>
    );
  }
}

const MapStateToProps = (state: any) => ({
  User: state.userState.userList,
  CurrentUser: state.loggedUserState.loggedInUser,
});

const MapDispatchToProps = (dispatch: any) => {
  return {
    GetUserList: (id: string) => dispatch(GetUserList(id)),
    GetLoggedInUser: () => dispatch(GetLoggedInUser()),
    GetUserById: (id: string) => dispatch(GetUserById(id))
  };
};

export default withRouter(connect(MapStateToProps, MapDispatchToProps)(Client));
