export type User = {
  id: number;
  email_address: string;
  password?: string;
  salt?: string;
  first_name?: string;
  last_name?: string;
  client_id?: number;
  user_type_id?: number;
  user_type_name?: string;
  user_type_tag?: string;
  is_active?: number;
}

export type LoginRequest = {
  email?: string;
  password?: string;
}
export type ForgetPasswordRequest = {
  email?: string;
}
export type ResetPasswordRequest = {
  reset_code?: string;
  password?: string;
  new_password?: string;
}

export type LoginResponse = {
  user: User;
  jwt: string;
  refresh: string;
}

// User Action Types
export enum UserActions {
  SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER",
  GET_LOGGED_IN_USER = "GET_LOGGED_IN_USER",
}

// Login User Action Type
interface SetLoggedInUser {
  type: typeof UserActions.SET_LOGGED_IN_USER,
  user: User,
}

interface GetLoggedInUser {
  type: typeof UserActions.GET_LOGGED_IN_USER,
  user: User,
}

// Export the Action Type
export type UserActionTypes = SetLoggedInUser | GetLoggedInUser;