import { useEffect } from 'react';
import * as React from 'react';
import { Button } from '../Button/Button';
import { ModalDialog, ModalDialogContent, ModalDialogHeader } from './ModalDialog';

type DeleteProps = {
    closeModal: () => void;
}

export const Delete: React.FC<DeleteProps> = (props: DeleteProps) => {
    useEffect(() => {
        var element = document.getElementById("delete");
        element?.classList.add('in');
    }, []);

    const confirmYes = (e: React.MouseEvent<HTMLElement>) => {
        localStorage.setItem("Delete","true");
        props.closeModal();

        // Get the Parent Dialog
        let parentDialog = e.currentTarget.parentElement?.parentElement?.parentElement;

        // Add the Class
        parentDialog?.classList.remove("in");

        // Remove the Overlay
        document.body.classList.remove('modal-open');
    }

    const confirmNo = (e: React.MouseEvent<HTMLElement>) => {
        localStorage.setItem("Delete","false");
        props.closeModal();

        // Get the Parent Dialog
        let parentDialog = e.currentTarget.parentElement?.parentElement?.parentElement;

        // Add the Class
        parentDialog?.classList.remove("in");

        // Remove the Overlay
        document.body.classList.remove('modal-open');
    }

    return (
        <>
        <ModalDialog id="delete">
            <ModalDialogHeader title="CONFIRM DELETE?" closeButton={true} onClose={props.closeModal} />
            <ModalDialogContent>
                <div className="addusers-form-wrapper">
                    <div className="form-row delete-content">Deleting this Folder will delete all Sub Folders and Files within this Folder, Please Confirm you wish to Delete</div>
                    <div className="form-row">
                        <Button id="confirm-yes" onClick={confirmYes} disabled={false}>Yes</Button>
                    </div>
                    <div className="form-row">
                        <Button id="confirm-no" onClick={confirmNo} disabled={false}>No</Button>
                    </div>
                </div>
            </ModalDialogContent>
        </ModalDialog>
        </>
    );
}
