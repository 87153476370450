import * as React from 'react';

type UserInfoProps = {
  name?:string;
  imageUrl?:string;
}

export const UserInfo: React.FC<React.PropsWithChildren<UserInfoProps>> = (props: React.PropsWithChildren<UserInfoProps>) => {

  const [userName, setUsername] = React.useState('');

  React.useEffect(() => {
    // fetching name from storage to display on user profile
    const fetchedUser = JSON.parse(localStorage.getItem("userPref")+"");
    if(fetchedUser) {
      setUsername(`${fetchedUser.first_name} ${fetchedUser.last_name}`);
    } else {
      setUsername('null');
    }
  }, [userName]);

  return(
    <div className="sidebar-user-section">
      <div className="user-col">
        <img src={props.imageUrl ? props.imageUrl : `${process.env.PUBLIC_URL}/images/user-icon.png`} alt={props.name ? props.name : 'Anonymous User'} />
      </div>
      <div className="user-name-title">{userName ? userName : ''}</div>
    </div>
  );
}
