import * as React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { GetClientList } from "../../../app/actions/client";
import { GetLoggedInUser } from "../../../app/actions/user";
import { Client } from "../../../app/types/client";
import { User } from "../../../app/types/user";
import AuthVerifyOnClick from "../../../common/AuthVerifyOnClick";
import { AddClientDialog } from "../../components/ModalDialog/AddClient";

type DashboardProps = {
  GetClientList(userId: string): [];
  GetLoggedInUser(): [];
  Client: Client;
  CurrentUser: User;
};

class Dashboard extends React.Component<DashboardProps> {
  state = {
    userId: 0,
    loggedUsertype: 0,
    clientList: [],
    fullList: [],
    redirect: null,
    showFolderModal: false,
  };

  componentDidMount = async () => {
    document.body.classList.remove("login-bg");

    await this.props.GetLoggedInUser();
    this.setState({
      loggedUsertype: this.props.CurrentUser?.user_type_id,
      userId: this.props.CurrentUser?.id,
    });

    await this.props.GetClientList(this.state.userId.toString());
    this.setState({
      clientList: this.props.Client,
      fullList: this.props.Client,
    });

    if (this.state?.clientList[0] && this.state.loggedUsertype !== 1) {
      this.setState({
        redirect: `/browser/${this.state?.clientList[0]["object_id"]}`,
      });
    }
  };

  updateClientList = async () => {
    AuthVerifyOnClick();
    await this.props.GetClientList(this.state.userId.toString());
    this.setState({
      clientList: this.props.Client,
      fullList: this.props.Client,
    });
    setTimeout(() => {
      document.body.classList.remove("modal-open");
      this.setState({ showFolderModal: false });
    }, 800);
  };

  searchFiles = (e: React.KeyboardEvent<HTMLInputElement>) => {
    AuthVerifyOnClick();
    let query = (e.target as HTMLInputElement).value.toLowerCase();
    if (query.trim()) {
      let filteredData = this.state.fullList?.filter((userData) => {
        let searchStringCriteria: string = userData["name"];
        searchStringCriteria =
          searchStringCriteria + " " + userData["long_path"];
        searchStringCriteria = searchStringCriteria + " " + userData["url_tag"];
        return searchStringCriteria?.toLowerCase().includes(query);
      });
      if (filteredData !== undefined) {
        this.setState({ clientList: filteredData });
      }
    } else {
      if (this.state.fullList !== undefined) {
        let filteredData = this.state.fullList;
        this.setState({ clientList: filteredData });
      }
    }
  };
  createFolderClick = () => {
    AuthVerifyOnClick()
    document.body.classList.add("modal-open");
    this.setState({ showFolderModal: true });
  };
  closeFolderModal = () => {
    AuthVerifyOnClick();
    this.setState({ showFolderModal: false });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <div className="row">
          <div className="dashboard-content-section">
            <div className="dashboard-header-section">
              <h2>Dashboard</h2>
              <div className="dashboard-search-section">
                <div className="row">
                  <div className="col-md-5">
                    <div className="search-wrapper">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search Client"
                        autoComplete="Off"
                        onKeyUp={this.searchFiles}
                      />
                      <button
                        type="button"
                        name="search"
                        className="search-icon"
                      ></button>
                    </div>
                  </div>
                  {this.state.loggedUsertype === 1 && (
                    <div className="col-md-7">
                      <div className="search-section-button">
                        <ul>
                          <li>
                            <button
                              type="button"
                              name="create-button"
                              onClick={this.createFolderClick}
                            >
                              Create Client Folder
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="dashboard-data-section">
              <div className="table-responsive-xl">
                <table width="100%" className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state?.clientList?.map((client, index) => {
                      return (
                        <tr
                          className={index % 2 === 0 ? `odd` : `even`}
                          key={client["id"]}
                        >
                          <td>
                            <Link
                              to={`${process.env.PUBLIC_URL}/browser/${client["object_id"]}`}
                            >
                              {client["name"]}
                            </Link>
                          </td>
                        </tr>
                      );
                    })}

                    {this.state.clientList?.length === 0 && (
                      <tr className="even">
                        <td className="not-available">No Data Available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {this.state.showFolderModal && (
          <AddClientDialog
            refreshList={this.updateClientList}
            closeModal={this.closeFolderModal}
          />
        )}
      </>
    );
  }
}

const MapStateToProps = (state: any) => {
  return {
    Client: state.clientState.clientList,
    CurrentUser: state.loggedUserState.loggedInUser,
  };
};

const MapDispatchToProps = (dispatch: any) => {
  return {
    GetClientList: (id: string) => dispatch(GetClientList(id)),
    GetLoggedInUser: () => dispatch(GetLoggedInUser()),
  };
};

export default connect(MapStateToProps, MapDispatchToProps)(Dashboard);
