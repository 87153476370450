import { User, UserActions, UserActionTypes } from "../types/user";

// Logged-in UserState
interface LoggedUserState {
  loggedInUser?: User,
  currentUser?: User
}

// Set the initial (empty) state
const initialState: LoggedUserState = {
  loggedInUser: undefined
}

const loggedUserReducer = (state: LoggedUserState = initialState, action: UserActionTypes): LoggedUserState => {
  switch (action.type) {
    case UserActions.SET_LOGGED_IN_USER:
      return {
        loggedInUser: action.user,
      }
    case UserActions.GET_LOGGED_IN_USER:
      return {
        loggedInUser: action.user
      }
    default:
      return state;
  }
}

export default loggedUserReducer;