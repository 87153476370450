import * as React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Folder } from "../../../app/types/folder";
import { User } from "../../../app/types/user";
import { debounce } from "lodash";
import { GetFolderList, GetParentHierarchy, SearchObjects } from "../../../app/actions/folder";
import { GetLoggedInUser } from "../../../app/actions/user";
import { withRouter, RouteComponentProps } from "react-router";
import { AddNewFolderDialog } from "../../components/ModalDialog/AddNewFolder";
import { EditFileNameDialog } from "../../components/ModalDialog/EditFileName";
import { UploadFileDialog } from "../../components/ModalDialog/UploadFile";
import { appAPI } from "../../../app/api-conn";
import { Delete } from "../../components/ModalDialog/Delete";
import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";
import { isLoggedIn } from '../../../app/api-conn';
import AuthVerifyOnClick from "../../../common/AuthVerifyOnClick";

type BrowserProps = {
  GetFolderList(parentId: string): [];
  SearchObjects(keyword: string): [];
  GetParentHierarchy(parentId: string): [];
  GetLoggedInUser(): [];
  Folder: Folder;
  Parents: Folder;
  CurrentUser: User;
};

const DarkBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  ${() =>
    css`
      display: block;
      position: fixed;
    `}
`;

class Browser extends React.Component<BrowserProps & RouteComponentProps<any>> {
  state = {
    parentId: this.props?.match?.params?.id!,
    parent: "",
    backPage: "",
    folderList: [],
    parentList: [],
    fullList: [],
    redirect: null,
    loggedUsertype: 0,
    canManage: false,
    canDoAction: false,
    todos: [{ id: "" }],
    showFolderModal: false,
    showDropdown: false,
    showUploadModal: false,
    showDeleteModal: false,
    loadingOverlay: false,
    showEditFileNameModal: false,
    idOfEditFileName:0,
    fileName:"",
    filterText: "",
  };

  componentDidMount = async () => {
    document.body.classList.remove("login-bg");

    // Load page data
    this.loadPageData();

    localStorage.setItem("Delete", "undefined");
  };

  getParentId = async () => {
    const URL = `/client/get-object/` + this.state.parentId;
    await appAPI
      .get(URL, {
        headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
      })
      .then((response:any) => {
        if (response.status === 200 || response.status === 201) {
          let data = response?.data?.object;
          if (
            this.props.CurrentUser?.user_type_id !== 1 &&
            this.props.CurrentUser?.client_id?.toString() !==
              data?.client_id.toString()
          ) {
            this.setState({ redirect: "/dashboard" });
          } else {
            this.setState({ parent: data?.parent_id });
          }
        } else {
          console.log("Something is wrong !!");
        }
      })
      .catch((error:any) => {
        console.log("Error: " + error?.response?.data?.message);
      });
  };

  loadPageData = async () => {
    this.setState({ filterText: "" });

    // Get logged-in user type
    await this.props.GetLoggedInUser();
    this.setState({
      loggedUsertype: this.props.CurrentUser?.user_type_id,
    });
    this.isAllowed();

    // Get folder & files listing for selected folder ID
    await this.props.GetFolderList(this.state.parentId.toString());
    if (this.props.Folder) {
      this.setState({
        folderList: this.props.Folder,
        fullList: this.props.Folder,
      });
    } else {
      this.setState({
        folderList: [],
        fullList: [],
      });
    }

    // Get Parent Object ID of current folder ID
    this.getParentId();

    // Breadcrumb hierarchy listing
    await this.props.GetParentHierarchy(this.state.parentId.toString());
    this.setState({ parentList: this.props.Parents });

    // Set Back button URL
    if (this.state.parent) {
      this.setState({ backPage: "/browser/" + this.state.parent });
    } else {
      this.setState({ backPage: "/dashboard" });
    }
  };

  componentDidUpdate = async () => {
    // Load page data
    if(this.props?.match?.params?.id! !== this.state.parentId){
      if(isLoggedIn()){
        this.setState({
          parentId: this.props?.match?.params?.id!
        });
        this.loadPageData();
      } else {
        window.location.reload();
      }
    }
    
    var confirmDelete = localStorage.getItem("Delete");
    if (confirmDelete === "true") {
      if (this.state.loadingOverlay === false) {
        this.setState({ loadingOverlay: true });
      }
      // Call the API Method
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      };

      for (let item = 1; item < this.state.todos.length; item++) {
        const URL =
          `/client/delete-file-object/` + this.state.todos[item]["id"];
        appAPI
          .delete(URL, { headers })
          .then((response:any) => {
            if (response.status === 200 || response.status === 201) {
              this.updateList();
              localStorage.setItem("Delete", "undefined");
              this.setState({ loadingOverlay: false });
              console.log("Client Deleted successfully");
            } else {
              console.log("Something is wrong !!");
            }
          })
          .catch((error:any) => {
            console.log("Error: " + error?.response?.data?.message);
          });
      }
    }
  };

  updateList = async () => {
    AuthVerifyOnClick();

    // If Delete or Edit operation performed on filtered data
    if (this.state.filterText.trim()) {
      await this.props.SearchObjects(this.state.filterText.trim());
      if (this.props.Folder) {
        this.setState({ folderList: this.props.Folder });
      } else {
        this.setState({ folderList: [] });
      }
    } 
    // If any operation is performed on non-filtered data
    else {
      await this.props.GetFolderList(this.state.parentId.toString());
      if (this.props.Folder) {
        this.setState({
          folderList: this.props.Folder,
          fullList: this.props.Folder,
        });
      } else {
        this.setState({
          folderList: [],
          fullList: [],
        });
      }
    }
    
    setTimeout(() => {
      document.body.classList.remove("modal-open");
      this.setState({ showFolderModal: false });
      this.setState({ showEditFileNameModal: false });
    }, 800);
  };

  searchFiles = debounce(async keyword => {
    AuthVerifyOnClick();
    let query = keyword.toLowerCase();

    if (query.trim()) {
      await this.props.SearchObjects(keyword);
      if (this.props.Folder) {
        this.setState({ folderList: this.props.Folder });
      } else {
        this.setState({ folderList: [] });
      }

    } else {
      if (this.state.fullList !== undefined) {
        let filteredData = this.state.fullList;
        this.setState({ folderList: filteredData });
      } else {
        this.setState({ folderList: [] });
      }
    }
  }, 500);
  toggleDropDownButton = () => {
    AuthVerifyOnClick();
    this.setState({ showDropdown: !this.state.showDropdown });
  };
  createFolderClick = () => {
    AuthVerifyOnClick();
    this.setState({ filterText: "" });
    this.searchFiles("")
    document.body.classList.add("modal-open");
    this.setState({ showFolderModal: true });
  };
  closeFolderModal = () => {
    AuthVerifyOnClick();
    this.setState({ showFolderModal: false });
  };
  uploadFileClick = () => {
    AuthVerifyOnClick();
    this.setState({ filterText: "" });
    this.searchFiles("")
    document.body.classList.add("modal-open");
    this.setState({ showUploadModal: true });
  };
  closeUploadModal = () => {
    AuthVerifyOnClick();
    this.setState({ showUploadModal: false });
  };
  closeDeleteModal = () => {
    AuthVerifyOnClick();
    this.setState({ showDeleteModal: false });
  };
  deleteSelected = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
    if (this.state?.todos?.length > 1 && this.state?.todos[1]?.id !== "") {
      document.body.classList.add("modal-open");
      this.setState({ showDeleteModal: true });
    }
  };
  handleCheckBox = (e: any) => {
    const { name, value, checked } = e.target;

    //collect data individual click/check
    if (checked && name !== "select_all") {
      let todos = [...this.state.todos];
      todos.push({
        id: e.target.value,
      });
      this.setState({ todos });
    } else if (name !== "select_all") {
      let todos = [...this.state.todos];
      let newList = todos.filter((file) => file.id !== value);
      this.setState({ todos: newList });
    }

    //collect data for select all click/check
    if (checked && name === "select_all") {
      let todos = [...this.state.todos];
      this.state.folderList.map((file: Folder) => {
        todos.push({
          id: file.id.toString(),
        });
        return { ...file, isChecked: checked };
      });
      this.setState({ todos });
    } else if (!checked && name === "select_all") {
      this.setState({ todos: [{ id: "" }] });
    }

    // Manage/Sync Check and Uncheck boxes on click
    if (name === "select_all") {
      let tempFile = this.state.folderList.map((folder: Folder) => {
        return { ...folder, isChecked: checked };
      });
      this.setState({ folderList: tempFile });
    } else {
      let tempFile = this.state.folderList.map((folder: Folder) =>
        folder.id.toString() === value
          ? { ...folder, isChecked: checked }
          : folder
      );
      this.setState({ folderList: tempFile });
    }
  };
  isCheckedSelectAll = () => {
    if (this.state.folderList?.length < 1) {
      return false;
    }
    if (
      !this.state.folderList?.some(
        (folder: any) => folder?.isChecked !== true
      )
    ) {
      return true;
    }
    return false;
  };
  isAllowed = () => {
    if ([1, 2, 4].includes(this.state.loggedUsertype)) {
      this.setState({ canDoAction: true });
    } else {
      this.setState({ canDoAction: false });
    }
    if ([1].includes(this.state.loggedUsertype)) {
      this.setState({ canManage: true });
    } else {
      this.setState({ canManage: false });
    }
  };

  editFileName = (folderId:number, fileName:string) => {
    document.body.classList.add("modal-open");
    this.setState({ showEditFileNameModal: true });
    this.setState({idOfEditFileName:folderId});
    this.setState({fileName})
  };
  closeEditFileNameModal = () => {
    AuthVerifyOnClick();
    this.setState({ showEditFileNameModal: false });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <div className="row">
          <div className="dashboard-content-section">
            <div className="dashboard-header-section">
              <h2>File Browser</h2>
              <div className="dashboard-search-section">
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-6 col-lg-2">
                    <div className="search-section-back">
                      <Link to={this.state.backPage}>
                        <button type="button" name="create-button">Go Back</button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                    <div className="search-wrapper">
                      {
                        <input
                          type="text"
                          name="search"
                          placeholder="Search File"
                          autoComplete="Off"
                          value={this.state.filterText}
                          onChange={(e) => {
                            this.setState({ filterText: e.target.value });
                            this.searchFiles(e.target.value)}
                          }
                        />
                      }
                      <button
                        type="button"
                        name="search"
                        className="search-icon"
                      ></button>
                    </div>
                  </div>
                  {this.state.canDoAction && (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                      <div className="search-section-button">
                        <ul>
                          <li>
                            <button
                              type="button"
                              name="create-button"
                              onClick={this.createFolderClick}
                            >
                              Create Folder
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              name="create-button"
                              onClick={this.uploadFileClick}
                            >
                              Upload File(s)
                            </button>
                          </li>
                          {this.state.canManage && <li>
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-primary dropdown-toggle"
                                onClick={this.toggleDropDownButton}
                              >
                                Action
                              </button>
                              <div
                                className={`dropdown-menu ${
                                  this.state.showDropdown ? "show" : ""
                                }`}
                              >
                                <ul>
                                  <li>
                                    <div
                                      className="dropdown-item delete"
                                      onClick={this.deleteSelected}
                                    >
                                      Delete Selected{" "}
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li> }
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="dashboard-data-section">
              <section>
                <nav>
                  <ol className="cd-breadcrumb">
                    <li>
                      <Link to={`/dashboard`}>Home</Link>
                    </li>

                    {this.state?.parentList
                      ?.reverse()
                      .map((object: Folder, index) => {
                        if (this.state.parentList.length === index + 1) {
                          return (
                            <li key={index} className="current">
                              {object.short_path.replace("/", "")}
                            </li>
                          );
                        } else {
                          return (
                            <li key={index}>
                              <Link to={`/browser/${object.id}`}>
                                {object.short_path.replace("/", "")}
                              </Link>
                            </li>
                          );
                        }
                      })}
                  </ol>
                </nav>
              </section>

              <div className="table-responsive-xl">
                <table width="100%" className="table">
                  <thead>
                    <tr>
                      {this.state.canManage && <th className="input-check">
                        <input
                          type="checkbox"
                          name="select_all"
                          checked={this.isCheckedSelectAll()}
                          onChange={(e) => this.handleCheckBox(e)}
                        />
                      </th> }
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state?.folderList?.map((folder, index) => {
                      return (
                        <tr
                          className={index % 2 === 0 ? `odd` : `even`}
                          key={folder["id"]}
                        >
                          {this.state.canManage && <td style={{ width: "3%" }}>
                            <input
                              type="checkbox"
                              name={`file${folder["id"]}`}
                              value={folder["id"]}
                              checked={folder["isChecked"] || false}
                              onChange={(e) => this.handleCheckBox(e)}
                            />
                          </td> }
                          <td>
                            {folder["is_folder"] === 1 && (
                              <Link
                                to={`${process.env.PUBLIC_URL}/browser/${folder["id"]}`}
                              >
                                <i className="folder-icon"></i> {folder["short_path"]}
                              </Link>
                            )}
                            {folder["is_folder"] !== 1 && (
                              <Link
                                to={`${process.env.PUBLIC_URL}/browser/view-file/${folder["id"]}`}
                              >
                                {folder["short_path"]}
                              </Link>
                            )}
                            {this.state.canManage && 
                            <span>
                              <i  className="edit-icon" onClick={()=>this.editFileName(folder["id"], folder["short_path"])}></i>
                            </span> }
                          </td>
                        </tr>
                      );
                    })}

                    {this.state.folderList?.length === 0 && (
                      <tr className="even">
                        <td colSpan={2} className="not-available">
                          No Data Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {this.state.canDoAction && this.state.showFolderModal && (
          <AddNewFolderDialog
            parentId={this.state.parentId}
            refreshList={this.updateList}
            closeModal={this.closeFolderModal}
          />
        )}
        {this.state.canDoAction && this.state.showUploadModal && (
          <UploadFileDialog
            parentId={this.state.parentId}
            refreshList={this.updateList}
            closeModal={this.closeUploadModal}
          />
        )}
        {this.state.canManage && this.state.showDeleteModal && (
          <Delete closeModal={this.closeDeleteModal} />
        )}
        {this.state.loadingOverlay && (
          <DarkBackground>
            <div style={{ position: "fixed", top: "50%", left: "50%" }}>
              <LoadingOverlay
                active={true}
                // spinner={<BounceLoader />}
                spinner={true}
                text="Processing..."
              ></LoadingOverlay>
            </div>
          </DarkBackground>
        )}
         {this.state.canManage && this.state.showEditFileNameModal && (
          <EditFileNameDialog
            parentId={this.state.parentId}
            refreshList={this.updateList}
            idOfEditFileName={this.state.idOfEditFileName}
            closeModal={this.closeEditFileNameModal}
            fileName={this.state.fileName}
          />
        )}
      </>
    );
  }
}

const MapStateToProps = (state: any) => ({
  Folder: state.folderState.folderList,
  Parents: state.folderState.parentList,
  CurrentUser: state.loggedUserState.loggedInUser,
});

const MapDispatchToProps = (dispatch: any) => {
  return {
    GetFolderList: (id: string) => dispatch(GetFolderList(id)),
    SearchObjects: (keyword: string) => dispatch(SearchObjects(keyword)),
    GetParentHierarchy: (id: string) => dispatch(GetParentHierarchy(id)),
    GetLoggedInUser: () => dispatch(GetLoggedInUser()),
  };
};

export default withRouter(
  connect(MapStateToProps, MapDispatchToProps)(Browser)
);
