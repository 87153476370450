import { combineReducers } from 'redux';
import loggedUserReducer from './reducers/user';
import clientReducer from './reducers/client';
import folderReducer from './reducers/folder';
import userReducer from './reducers/users';
import commentReducer from './reducers/comment';

// Combine all of the reducers into a single, root reducer
export const rootReducer = combineReducers({
    loggedUserState: loggedUserReducer,
    userState: userReducer,
    clientState: clientReducer,
    folderState: folderReducer,
    commentState: commentReducer
});

// Export the App State of type rootReducer
export type AppState = ReturnType<typeof rootReducer>;
