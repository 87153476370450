import axios from 'axios';

export const appAPI = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
})

const parseJwt = (token: any) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const isLoggedIn = (): boolean => {
  let jwt = localStorage.getItem("jwt");
  if (jwt === "" || jwt === null) {
    return false;

  } else {
    const decodedJwt = parseJwt(jwt);
    if (decodedJwt) {
      if (decodedJwt.exp * 1000 < Date.now()) {
        // localStorage.clear();
        return false;

      } else {
        return true;
      }
    }
    return true;
  }
}
