export type Client = {
    id: number | string;
    name: string;
    url_tag: string;
    object_id: number | string;
    long_path: string;
}

export enum ClientActions {
    GET_CLIENT_LIST = "GET_CLIENT_LIST"
}

export interface GetClientList {
    type: typeof ClientActions.GET_CLIENT_LIST,
    clients: Client[]
}

export type ClientActionsType = GetClientList;