import * as React from 'react';
import { Link } from 'react-router-dom';

type HeaderProps = {
  
}

export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = (props: React.PropsWithChildren<HeaderProps>) => {
  return(
    <header className="top-header-wrapper">
        <div className="logo-wrapper"><Link to="/"><img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" /></Link></div>  
    </header>
  );
}
