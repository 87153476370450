import { appAPI } from './app/api-conn';

const parseJwt = (token:any) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

// Functionality to check JWT token expiry time and refresh JWT token
export const refreshToken = async () => {

  if(localStorage.getItem("jwt") !== ''){
    const decodedJwt = await parseJwt(localStorage.getItem("jwt"));

    if(decodedJwt){
      if (((decodedJwt.exp * 1000)-10000) < Date.now()) {
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("refresh"),
        };
        const URL = `/auth/refresh`;
        appAPI
          .post(URL,'', { headers })
          .then((response:any) => {
            if (response.status === 200 || response.status === 201) {
              let refreshResponse:any = response.data;

              // Set the JWT
              localStorage.setItem("jwt", refreshResponse.access_token);

              // Set the Refresh Token
              localStorage.setItem("refresh", refreshResponse.refresh_token);

            } else {
              console.log("Something is wrong !!");
            }
          })
          .catch((error:any) => {
            console.log("Error: " + error?.response?.data?.message);
          });
      }
    }
  }
}
