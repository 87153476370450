export type Folder = {
    id: number | string;
    short_path: string;
    long_path?: string;
    parent_id: number;
    is_folder?: number;
    client_id?: number;
}

export enum FolderActions {
    GET_FOLDER_LIST = "GET_FOLDER_LIST",
    GET_FOLDER_HIERARCHY = "GET_FOLDER_HIERARCHY",
    GET_PARENT_HIERARCHY = "GET_PARENT_HIERARCHY",
    SEARCH_OBJECTS = "SEARCH_OBJECTS",
}

export interface GetFolderList {
    type: typeof FolderActions.GET_FOLDER_LIST,
    folders: Folder[]
}

export interface GetFolderHierarchy {
    type: typeof FolderActions.GET_FOLDER_HIERARCHY,
    folders: Folder[]
}

export interface GetParentHierarchy {
    type: typeof FolderActions.GET_PARENT_HIERARCHY,
    folders: Folder[]
}

export interface SearchObjects {
    type: typeof FolderActions.SEARCH_OBJECTS,
    folders: Folder[]
}

export type FolderActionsType = GetFolderList | GetFolderHierarchy | GetParentHierarchy | SearchObjects;