import * as React from 'react';
import { FormInput } from '../../components/Form/Form';
import { SubmitButton } from '../../components/Button/Button';
import { appAPI } from '../../../app/api-conn';

type ChangePasswordProps = {
}

class ChangePassword extends React.Component<ChangePasswordProps> {
  // Set the States
  state = {
    oldPassword: '',
    password: '',
    repeatPassword: '',
    error: '',
    errorOldPassword: '',
    errorPassword: '',
    errorRetypePassword: '',
    apiResponse: ''
  };

  componentDidMount = () => {
    document.body.classList.remove('login-bg');
  }

  handleOldPassword = (e:any) => {
    this.setState({oldPassword: e.target.value});
  }
  handlePassword = (e:any) => {
    this.setState({password: e.target.value});
  }
  handleRepeatPassword = (e:any) => {
    this.setState({repeatPassword: e.target.value});
  }
  changePassword = () => {
    this.setState({errorOldPassword: '',errorPassword: '',errorRetypePassword: ''});
    let inputError = false;

    if(this.state.oldPassword.length < 1){
      inputError = true;
      this.setState({errorOldPassword: 'Please enter Old Password'});
    }
    if(this.state.password.length < 1){
      inputError = true;
      this.setState({errorPassword: 'Please enter New Password'});
    }
    if(this.state.password !== this.state.repeatPassword){
      inputError = true;
      this.setState({errorRetypePassword: 'Retype New Password is not matching with New Password'});
    }

    //Update password
    if( !inputError ){
      const inputObj = {
        "old_password": this.state.oldPassword,
        "new_password": this.state.password
      }

      // Call the API Method  
      const headers = {
        'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      };
      appAPI.post('/auth/change-password', inputObj, { headers })
        .then((response:any) => {
            if (response.status === 200 || response.status === 201) {
              this.setState({oldPassword: '',password: '',repeatPassword: ''});
              this.setState({apiResponse: 'Your password is changed successfully.'});
            } else {
              this.setState({errorRetypePassword: 'Something is wrong !!'});
            }
        }).catch((error:any) => {
          this.setState({errorRetypePassword: error.response.data.message});
        })
    }
  }

  render() {
    return(
      <>
      <div className="row">
        <div className="dashboard-content-section">
          <div className="dashboard-header-section">
            <h2>Change Password</h2>
            <hr/>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="change-password-section">
                <div className="change-password-wrapper">
                  <h3 className="text-success">{this.state.apiResponse}</h3>
                  <FormInput type="password" id="old_password" placeholder="Old Password" value={this.state.oldPassword} autoComplete="new-password" onChange={this.handleOldPassword} />
                  <span className="dialog-error">{this.state.errorOldPassword}</span>
                  <FormInput type="password" id="password" placeholder="New Password" value={this.state.password} autoComplete="new-password" onChange={this.handlePassword} />
                  <span className="dialog-error">{this.state.errorPassword}</span>
                  <FormInput type="password" id="retype_password" placeholder="Retype New Password" value={this.state.repeatPassword} autoComplete="new-password" onChange={this.handleRepeatPassword} />
                  <span className="dialog-error">{this.state.errorRetypePassword}</span>
                  <SubmitButton id="change-password" value={'Change Password'} onClick={this.changePassword} />              
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      </>
    );
  }
}

export default ChangePassword;