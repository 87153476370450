import { useState, useEffect } from 'react';
import * as React from 'react';
import validator from 'validator';
import { Button } from '../Button/Button';
import { FormInput } from '../Form/Form';
import { appAPI } from '../../../app/api-conn';
import { ModalDialog, ModalDialogContent, ModalDialogHeader } from '../ModalDialog/ModalDialog';

type RegisterClientDialogProps = {
    closeModal: () => void;
    refreshList: () => void;
    clientId:string;
}

export const RegisterClientDialog: React.FC<RegisterClientDialogProps> = (props: RegisterClientDialogProps) => {
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [userType, setUserType] = useState<string>('');
    const [ApiResponse, setApiResponse] = useState<string>('');
    const [fnameError, setFnameError] = useState<string>('');
    const [lnameError, setLnameError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [typeError, setTypeError] = useState<string>('');
    const [processing, setProcessing] = useState<Boolean>(false);

    useEffect(() => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setUserType('');
        var element = document.getElementById("addUser");
        element?.classList.add('in');
    }, []);

    const handleFirstName = (e: any) => {
        setFirstName(e.target.value);
    }
    const handleLastName = (e: any) => {
        setLastName(e.target.value);
    }
    const handleEmail = (e: any) => {
        setEmail(e.target.value);
    }
    const handleUserType = (e: any) => {
        setUserType(e.target.value);
    }
    const saveChanges = () => {
        setApiResponse('');
        setFnameError('');
        setLnameError('');
        setEmailError('');
        setTypeError('');

        let inputError = false;
        if(firstName.length < 1){
            inputError = true;
            setFnameError('Please enter first name');
        }
        if(lastName.length < 1){
            inputError = true;
            setLnameError('Please enter last name');
        }
        if( !validator.isEmail(email)){
            inputError = true;
            setEmailError('Please enter valid email address');
        }
        if(userType.length < 1){
            inputError = true;
            setTypeError('Please select user type');
        }

        if(!inputError){
            setProcessing(true);

            const inputObj = {
                "client_id": parseInt(props.clientId), 
                "email": email,
                "first_name": firstName,
                "last_name": lastName,
                "user_type_id": parseInt(userType)
                }
    
            // Call the API Method  
            const headers = {
                'Authorization': 'Bearer ' + localStorage.getItem("jwt")
            };
            appAPI.post('/auth/add-user', inputObj, { headers })
                .then((response:any) => {
                    if (response.status === 200 || response.status === 201) {
                        setProcessing(false);
                        props.refreshList();
                        setFirstName('');
                        setLastName('');
                        setEmail('');
                        setUserType('');
                        setApiResponse('User is added successfully');
                    } else {
                        setTypeError('Something is wrong !!'); 
                    }
                }).catch((error:any) => {
                    setProcessing(false);
                    setTypeError(error.response.data.message);
                })
        }
    }

    return (
        <>
        <ModalDialog id="addUser">
            <ModalDialogHeader title="Register Client Users" closeButton={true} onClose={props.closeModal} />
            <ModalDialogContent>
                <div className="addusers-form-wrapper">
                    <h3 className="text-success">{ApiResponse}</h3>
                    <FormInput type="text" id="registerFirstName" placeholder="First Name" value={firstName} onChange={handleFirstName} />
                    <span className="dialog-error user-reg">{fnameError}</span>
                    <FormInput type="text" id="registerLastName" placeholder="Last Name" value={lastName} onChange={handleLastName} />
                    <span className="dialog-error user-reg">{lnameError}</span>
                    <FormInput type="text" id="registerEmail" placeholder="Email Address" value={email} onChange={handleEmail} />
                    <span className="dialog-error user-reg">{emailError}</span>
                    <div className="form-row">
                    <select id="user_type" value={userType} onChange={handleUserType}>
                        <option value="">Select User Type</option>
                        <option value="2">Administrator</option>
                        <option value="3">Admin Viewer</option>
                        <option value="4">Site Manager</option>
                        <option value="5">Site Viewer</option>
				    </select>
                    </div>
                    <span className="dialog-error user-reg">{typeError}</span>
                    {!processing && <div className="form-row">
                        <Button id="save-register-user" onClick={saveChanges}>Register Client User</Button>
                    </div>}
                    {processing && <div className="col-md-12">
                        <div className="form-row">
                        <img src={`${process.env.PUBLIC_URL}/images/loading-buffering.gif`} alt="Loading" width={"20px"} height={"20px"}/>
                        </div>
                    </div>}
                </div>
            </ModalDialogContent>
        </ModalDialog>
        </>
    );
}
