import { Dispatch } from "redux";
import { User, UserActions } from "../types/user";

export const SetLoggedInUser = (user: User) => async (dispatch: Dispatch) => {
  dispatch({
    type: UserActions.SET_LOGGED_IN_USER,
    user: user
  });
}

export const GetLoggedInUser = () => async (dispatch: Dispatch) => {
  // get logged-in user data
  let userDetails = await JSON.parse(localStorage.getItem('userPref')!);
  dispatch({
    type: UserActions.GET_LOGGED_IN_USER,
    user: userDetails
  });
}
