import * as React from "react";
import { useState } from "react";
import validator from "validator";
import { LoginFormInput } from "../../components/Form/Form";
import { LoginButton, LinkButton } from "../../components/Button/Button";
import { ForgetPasswordRequest } from "../../../app/types";
import { appAPI } from "../../../app/api-conn";

type ForgotPasswordPageProps = {};

export const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = (
  props: ForgotPasswordPageProps
) => {
  // Set the States
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>();
  const [successTextToShow, setsuccessTextToShow] = useState<string>("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError("");

    let inputError = false;
    if ( !validator.isEmail(email) ) {
      inputError = true;
      setError("Please enter valid email address");
    }

    if (!inputError) {
      // Build the Request
      let credentials: ForgetPasswordRequest = {
        email: email.toLowerCase(),
      };

      // Call the API Method
      appAPI
        .post("/auth/send-reset-password-link", credentials)
        .then((res:any) => {
          console.log("forget pass res", res);
          if (res.data.status === "success") {
            setsuccessTextToShow(
              "An email has been sent to the address you entered with a link to reset your password. Please also check your spam folder for reset link email."
            );
          }
        })
        .catch((error:any) => {
          // Get the Error Object
          let apiError: any = error?.response?.data;
          setError(apiError?.message);
        });
    }
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  return (
    <section className="login-page-section">
      <div className="container">
        <div className="row">
          <div className="login-section">
            <div className="login-header">
              <img
                src={`${process.env.PUBLIC_URL}/images/login-page-logo.png`}
                alt="valcomm-logo"
              />
            </div>
            <div className="login-content-section">
              <div className="login-content-title">
                Forgot Password of your account
              </div>
              <div className="login-form-wrapper">
                  {successTextToShow !== "" && (
                    <h3 className="text-success">{successTextToShow}</h3>
                  )}
                <form onSubmit={handleSubmit} method="POST" hidden={successTextToShow? true : false}>
                  <LoginFormInput
                    type="text"
                    id="email"
                    placeholder="Email Address"
                    autoComplete={false}
                    icon="email-address-icon"
                    onChange={handleEmail}
                  />
                  <span className="dialog-error login-error">{error}</span>
                  <LoginButton id="login" value={"Reset Password"} />
                </form>
                <LinkButton
                  to="/auth/login"
                  class="forget-password-text"
                  value={"Back to Login"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
