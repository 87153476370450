import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./scss/style.scss";
import { Header } from "./ui/components/Header/Header";
import { SideNavigation } from "./ui/components/SideNavigation/SideNavigation";
import { LoginPage } from "./ui/pages/Login/Login";
import { ResetPasswordPage } from "./ui/pages/ResetPassword/ResetPassword";
import { ForgotPasswordPage } from "./ui/pages/ForgotPassword/ForgotPassword";
import Dashboard from "./ui/pages/Dashboard/Dashboard";
import Browser from "./ui/pages/Browser/Browser";
import FileDetail from "./ui/pages/Browser/FileDetail";
import Users from "./ui/pages/Users/Users";
import ChangePassword from "./ui/pages/ChangePassword/ChangePassword";
import PrivateRoute from "./ui/components/PrivateRoute/PrivateRoute";
import Staff from "./ui/pages/Staff/Staff";
import Client from "./ui/pages/Staff/Client";
import AuthVerify from "./common/AuthVerify";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/auth/login">
          <LoginPage />
        </Route>
        <Route path="/auth/forgot-password">
          <ForgotPasswordPage />
        </Route>
        <Route path="/auth/reset-password/:reset_code">
          <ResetPasswordPage />
        </Route>
        <>
          <Header />
          <div className="main-dashboard-wrapper">
            <div className="main-dashboard-section">
              <div className="container-fluid">
                <div className="row">
                  <SideNavigation />
                  <div className="col-md-8 col-lg-9">
                    <PrivateRoute path="/" component={Dashboard} exact />
                    <PrivateRoute
                      path="/browser/:id"
                      component={Browser}
                      exact
                    />
                    <PrivateRoute
                      path="/browser/view-file/:id(\d+)"
                      component={FileDetail}
                      exact
                    />
                    <PrivateRoute
                      path="/dashboard"
                      component={Dashboard}
                      exact
                    />
                    <PrivateRoute path="/users" component={Users} exact />
                    <PrivateRoute
                      path="/change-password"
                      component={ChangePassword}
                      exact
                    />
                    <PrivateRoute path="/users/staff" component={Staff} exact />
                    <PrivateRoute
                      path="/users/client/:id"
                      component={Client}
                      exact
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Switch>
      <AuthVerify />
    </Router>
  );
}

export default App;
