import { Dispatch } from "redux";
import { appAPI } from "../api-conn";
import { ClientActions, ClientActionsType } from "../types/client";

export const GetClientList = (userId: string) => async (dispatch: Dispatch<ClientActionsType>) => {
    const URL = `/client/all/` + userId;
    await appAPI.get(
        URL,
        { headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwt') } }
    ).then((res: any) => {
        dispatch({
            type: ClientActions.GET_CLIENT_LIST,
            clients: res.data.list
        });
    }).catch((error: any) => {

    });
}