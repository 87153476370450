import * as React from 'react';

type FormInputProps = {
  type: string;
  id: string;
  label?: string;
  placeholder?: string;
  value?: string | number;
  min?: number;
  max?: number;
  step?: number;
  icon?: string;
  autoComplete?: boolean | string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

type FormProfileInputProps = {
  type: string;
  id: string;
  label?: string;
  placeholder?: string;
  value?: string | number;
  min?: number;
  max?: number;
  step?: number;
  className?: string;
  autoComplete?: boolean;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

type FormProfileCheckboxProps = {
  type: string;
  id: string;
  label?: string;   
  value?: string | number;  
  className?: string;
  checked?: boolean;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

type FormIpProps = {
  type: string;
  id: string;
  label?: string;
  value?: string | number;
  minlength?: number;
  maxlength?: number;
  pattern?: string;
  size?: number;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

type FormTextAreaProps = {
  id: string;
  label?: string;
  value?: string | number;
  autoComplete?: boolean;
  rows?: number;
  onChange?(event: React.ChangeEvent<HTMLTextAreaElement>): void;
}

type selectOptions = {
  key: any,
  value: any
}
type FormSelectProps = {
  id: string;
  label?: string;
  options?: selectOptions[];
  className?: string;
  onChange?(event: React.ChangeEvent<HTMLSelectElement>): void;
  selected?:string;
}

export const FormInput: React.FC<FormInputProps> = (props: FormInputProps) => {
  let name = props.id.replace(/-/g, "_");
  let autoComplete = props.autoComplete ? "On" : "Off";
  return (
    <div className="form-row" id={"container-" + props.id}>
      {props.label &&
        <label className="input-label" htmlFor={props.id}>
          {props.label}
        </label>
      }
      <input type={props.type} id={props.id} name={name} value={props.value} placeholder={props.placeholder} step={props.step} min={props.min} max={props.max} onChange={props.onChange} autoComplete={autoComplete} />
    </div>
  );
}

export const LoginFormInput: React.FC<FormInputProps> = (props: FormInputProps) => {
  let name = props.id.replace(/-/g, "_");
  let autoComplete = props.autoComplete ? "On" : "Off";
  return (
    <div className="form-row login-icon-wrap" id={"container-" + props.id}>
      {props.label &&
        <label className="input-label" htmlFor={props.id}>
          {props.label}
        </label>
      }
      <i className={props.icon}></i>
      <input type={props.type} id={props.id} name={name} value={props.value} placeholder={props.placeholder} step={props.step} min={props.min} max={props.max} onChange={props.onChange} autoComplete={autoComplete} />
    </div>
  );
}

export const FormProfileInput: React.FC<FormProfileInputProps> = (props: FormProfileInputProps) => {
  let name = props.id.replace(/-/g, "_");
  let autoComplete = props.autoComplete ? "On" : "Off";
  return (
    <input type={props.type} id={props.id} name={name} value={props.value} placeholder={props.placeholder} className={props.className} onChange={props.onChange} autoComplete={autoComplete} />
  );
}


export const FormProfileCheckbox: React.FC<FormProfileCheckboxProps> = (props: FormProfileCheckboxProps) => {
  let name = props.id.replace(/-/g, "_");
  return (
    <input type={props.type} id={props.id} name={name} value={props.value}  className={props.className} onChange={props.onChange} checked={props.checked}/>
  );
}


export const FormIP: React.FC<FormIpProps> = (props: FormIpProps) => {
  let name = props.id.replace(/-/g, "_");
  return (
    <div className="form-control" id={"container-" + props.id}>
      {props.label &&
        <label className="input-label" htmlFor={props.id}>
          {props.label}
        </label>
      }
      <input type={props.type} id={props.id} name={name} value={props.value} minLength={props.minlength} maxLength={props.maxlength} pattern={props.pattern} size={props.size} onChange={props.onChange} />
    </div>
  );
}


export const FormTextArea: React.FC<FormTextAreaProps> = (props: FormTextAreaProps) => {
  let name = props.id.replace(/-/g, "_");
  let autoComplete = props.autoComplete ? "On" : "Off";
  return (
    <div className="form-control" id={"container-" + props.id}>
      {props.label &&
        <label className="input-label" htmlFor={props.id}>
          {props.label}
        </label>
      }
      <textarea className="textareaDialog" id={props.id} name={name} rows={props.rows} onChange={props.onChange} autoComplete={autoComplete}>{props.value}</textarea>

    </div>
  );
}


export const FormSelect: React.FC<FormSelectProps> = (props: FormSelectProps) => {
  let name = props.id.replace(/-/g, "_");
  return (
    <div className="form-control" id={"container-" + props.id}>
      {props.label &&
        <label className="input-label" htmlFor={props.id}>
          {props.label}
        </label>
      }
      <select id={props.id} name={name} className={props.className} onChange={props.onChange}>      {
        props.options?.map(element => {
            
          return <option value={element.value} selected={props.selected === element.value}>{element.key}</option>
        })
      }
      </select>
    </div>
  );
}
