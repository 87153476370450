import * as React from 'react';
import { ModalDialog, ModalDialogContent, ModalDialogHeader } from '../ModalDialog/ModalDialog';
import CheckboxTree from 'react-checkbox-tree';
import '../../../scss/react-checkbox-tree.scss';
import {GetFolderHierarchy} from "../../../app/actions/folder";
import {Folder} from "../../../app/types/folder";
import { connect } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';
import { Button } from '../Button/Button';
import { appAPI } from '../../../app/api-conn';
import AuthVerifyOnClick from '../../../common/AuthVerifyOnClick';

type FolderAccessDialogProps = {
    GetFolderHierarchy(parentId:string): [],
    closeModal: () => void,
    Folder:Folder,
    objectId:string,
    userId:number
}

class FolderAccessDialog extends React.Component<FolderAccessDialogProps> {
    state = {
        checked: [],
        expanded: [],
        halfChecked: [] as any,
        folderList: [],
        list: [],
        nodes: [],
        filterText: '',
        nodesFiltered: [],
        error: '',
        successMsg: '',
        processing: false,
        submit: false,
    };

    componentDidMount = async () =>  {
        var element = document.getElementById("folderAccess");
        element?.classList.add('in');
        await this.props.GetFolderHierarchy(this.props.objectId.toString());
        this.setState({folderList: this.props.Folder});
        let folders:any = [];
        this.state.folderList?.forEach((folder:Folder) => {
            if(this.props.objectId.toString() === folder.parent_id.toString()){
                folders.push({
                    value: folder.id,
                    label: folder.short_path
                });
            }else{
                this.findIndexNested(folders, folder);
            }
        });

        this.setState({list:folders, nodes:folders});
        this.setState({nodesFiltered: this.state.nodes});
        this.getChecked();
    }
    findIndexNested(folders:any, folder:Folder) {
        var _this3 = this;
        let folderTree = [ ...folders ];

        folderTree?.forEach((thisFolder:any) => {
            if (thisFolder.value.toString() === folder.parent_id.toString()) {
                if(thisFolder.children == null) thisFolder.children = [];
                thisFolder.children.push({
                    value: folder.id,
                    label: folder.short_path
                });
                return 1;
            }else{
                if(thisFolder.children){
                    _this3.findIndexNested(thisFolder.children, folder);
                }
            }
        });
    }

    getChecked = async() => {
        const userId = this.props.userId.toString();
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("jwt")
        };
        const URL = `/client/get-object-list-for-user/${userId}`;

        await appAPI.get(URL, { headers })
        .then((response:any) => {
            if (response.status === 200 || response.status === 201) {
                const ids: any[] = [];
                response?.data?.data?.forEach((id:any) => {
                    ids.push(id.file_object_id);
                });
                this.setState({ checked: ids});
            } else {
                this.setState({ error: 'Something is wrong !!' });
            }
        }).catch((error:any) => {
            console.log(error.response.data.message);
        })
    }

    onFilterChange = (e: any) => {
        AuthVerifyOnClick();
        this.setState({ filterText: e.target.value }, this.filterTree);
    }
    filterTree = () => {
        // Reset nodes back to unfiltered state
        if (!this.state.filterText) {
            this.setState((prevState) => ({
                nodesFiltered: this.state.nodes,
            }));

            return;
        }

        const nodesFiltered = () => (
            { nodesFiltered: this.state.nodes.reduce(this.filterNodes, []) }
        );

        this.setState(nodesFiltered);
        // console.log(this.state.nodesFiltered);
    }

    filterNodes = (filtered: any, node: any) => {
        const { filterText } = this.state;
        const children = (node.children || []).reduce(this.filterNodes, []);

        if (
            // Node's label matches the search string
            node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1 ||
            // Or a children has a matching node
            children.length
        ) {
            filtered.push({ ...node, children });
        }

        return filtered;
    }

    checkHalfCheckedElements = async (checkedId:string) =>{
        let superParentId = this.props.objectId.toString();
        await this.state.folderList?.forEach((folder:Folder) => {
            if(checkedId === folder.id.toString() && superParentId !== folder.parent_id.toString()){
                // Check existamce of this in checked list
                let checked : any[] = this.state.checked;
                let halfChecked : any[] = this.state.halfChecked;

                if(!this.handleCheckInList(checked, folder.parent_id)){
                    if(!this.handleCheckInList(halfChecked, folder.parent_id)){
                        this.state.halfChecked.push(folder.parent_id);
                        this.checkHalfCheckedElements(folder.parent_id.toString());
                    }
                }
            }
        });
    }
    handleCheckInList = (list:any[], val:any) => {
        return list.some(item => val.toString() === item.toString());
    }

    sendChecked = async () => {
        AuthVerifyOnClick();
        this.setState({ successMsg: "", error:"",processing: true});

        /* Check for all parent */
        this.state.checked?.forEach((checkedId:number) => {
            this.checkHalfCheckedElements(checkedId.toString());
        });

        const inputObj = {
            "user_id": this.props.userId,
            "object_ids": this.state.checked,
            "partial_ids": this.state.halfChecked
        }
        // Call the API Method  
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("jwt")
        };
        const URL = `/client/add-user-object-access`;

        await appAPI.post(URL, inputObj, { headers })
        .then((response:any) => {
            if (response.status === 200 || response.status === 201) {
                this.setState({processing: false, successMsg: "Folder Access is added successfully."});
                this.setState({halfChecked: []});
                setTimeout(() => {
                    this.props.closeModal();
                    document.body.classList.remove("modal-open");
                }, 800);
            } else {
                this.setState({ processing: false, error: 'Something is wrong !!' });
            }
        }).catch((error:any) => {
            this.setState({ processing: false, error: error?.response?.data?.message });
        })
    }

    render() {
        const {filterText} = this.state;
        return (
        <>
        <ModalDialog id="folderAccess">
            <ModalDialogHeader title="Manage Folder Access" closeButton={true} onClose={this.props.closeModal} />
            <ModalDialogContent>
                <h3 className="text-success">{this.state.successMsg}</h3>
                <span className="dialog-error user-reg">{this.state.error}</span>
                <div className="addusers-form-wrapper">
                    <div className='search-wrapper'>
                        <input
                            className="filter-text"
                            placeholder="Search Folder..."
                            type="text"
                            value={filterText}
                            onChange={this.onFilterChange}
                        />
                        <button type="button" name="search" className="search-icon"></button>
                    </div>
                    <CheckboxTree
                        checkModel="all"
                        nodes={this.state.nodesFiltered}
                        checked={this.state.checked}
                        expanded={this.state.expanded}
                        onCheck={checked =>{    AuthVerifyOnClick();
                            this.setState({ checked })}}
                        onExpand={expanded => {    AuthVerifyOnClick();
                            this.setState({ expanded })}}
                    />
                    {!this.state.processing && <div className="col-md-12">    
                        <div className="form-row">
                            <Button id="submit" onClick={this.sendChecked} disabled={this.state.submit}>Submit</Button>
                        </div>
                    </div>}
                    {this.state.processing && <div className="col-md-12">
                        <div className="form-row">
                        <img src={`${process.env.PUBLIC_URL}/images/loading-buffering.gif`} alt="Loading" width={"20px"} height={"20px"}/>
                        </div>
                    </div>}
                </div>
            </ModalDialogContent>
        </ModalDialog>
        </>
        );
    }
}

const MapStateToProps = (state: any) => ({
    Folder: state.folderState.folderList,
  });

const MapDispatchToProps = (dispatch:any) =>{
    return {
        GetFolderHierarchy: (id:string) => dispatch(GetFolderHierarchy(id)),
    };
};

export default connect(MapStateToProps, MapDispatchToProps) (FolderAccessDialog);