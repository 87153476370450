export type Comment = {
    id: number | string;
    user_id: number | string;
    file_object_id: number | string;
    posted_on: number | string;
    comment: string;
}

export enum CommentActions {
    GET_COMMENT_LIST = "GET_COMMENT_LIST"
}

export interface GetCommentList {
    type: typeof CommentActions.GET_COMMENT_LIST,
    comments: Comment[]
}

export type CommentActionsType = GetCommentList;