import { Comment, CommentActions, CommentActionsType } from "../types/comment";

interface CommentState {
    commentList?: Comment[]
}

const initialState: CommentState = {
    commentList: undefined
}

const commentReducer = (state: CommentState = initialState, action: CommentActionsType): CommentState => {
    switch (action.type) {
        case CommentActions.GET_COMMENT_LIST:
            return {
                commentList: action.comments
            }
        default:
            return state;
    }
}
export default commentReducer;