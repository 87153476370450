import { Dispatch } from "redux";
import { appAPI } from "../api-conn";
import { CommentActions, CommentActionsType } from "../types/comment";

export const GetCommentList = (userId: string) => async (dispatch: Dispatch<CommentActionsType>) => {
    // console.log(userId);
    const URL = `/client/browsers/object/get-comments/` + userId;
    await appAPI.get(
        URL,
        { headers: { "Authorization": 'Bearer ' + localStorage.getItem('jwt') } }
    ).then((res: any) => {
        // console.log(res.data.data);
        dispatch({
            type: CommentActions.GET_COMMENT_LIST,
            comments: res.data.data
        });
    }).catch((error: any) => {

    });
}