import * as React from "react";
import { useState } from "react";
import { LoginFormInput } from "../../components/Form/Form";
import { LoginButton, LinkButton } from "../../components/Button/Button";
import { useParams } from "react-router-dom";
import { ResetPasswordRequest } from "../../../app/types";
import { appAPI } from "../../../app/api-conn";

type ResetPasswordPageProps = {};

export const ResetPasswordPage: React.FC<ResetPasswordPageProps> = (
  props: ResetPasswordPageProps
) => {
  // Get Dynamic Reset Password Code from URL
  const { reset_code }: any = useParams();
  console.log("reset_code", reset_code);
  // Set the States
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorPassword, setErrorPassword] = useState<string>();
  const [error, setError] = useState<string>();
  const [successTextToShow, setsuccessTextToShow] = useState<string>("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError("");
    setErrorPassword("");
    setsuccessTextToShow("");

    let inputError = false;
    if (password.length < 1) {
      inputError = true;
      setErrorPassword("Please enter new password");
    }
    if (confirmPassword.length < 1) {
      inputError = true;
      setError("Please enter confirm new password");
    } else if (password !== confirmPassword) {
      inputError = true;
      setError("Confirm new password doesn't match!");
    }

    if (!inputError) {
      // Build the Request
      let credentials: ResetPasswordRequest = {
        reset_code: reset_code,
        password: password,
        new_password: confirmPassword,
      };

      // Call the API Method
      appAPI
        .post("/auth/reset-password", credentials)
        .then((res:any) => {
          console.log("forget pass res", res);
          if (res.data.status === "success") {
            setsuccessTextToShow(
              "Your password is updated. Please go back to login and try now."
            );
          }
        })
        .catch((error:any) => {
          // Get the Error Object
          let apiError: any = error?.response?.data;
          setError(apiError?.message);
        });
    }
  };

  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };
  const handleConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <section className="login-page-section">
      <div className="container">
        <div className="row">
          <div className="login-section">
            <div className="login-header">
              <img
                src={`${process.env.PUBLIC_URL}/images/login-page-logo.png`}
                alt="valcomm-logo"
              />
            </div>
            <div className="login-content-section">
              <div className="login-content-title">
                Reset Password for your account
              </div>
              <div className="login-form-wrapper">
              {successTextToShow !== "" && (
                    <h3 className="text-success">{successTextToShow}</h3>
                  )}
                <form onSubmit={handleSubmit} method="POST" hidden={successTextToShow? true : false}>
                  <LoginFormInput
                    type="password"
                    id="password"
                    placeholder="New Password"
                    autoComplete={false}
                    icon="password-input-icon"
                    onChange={handlePassword}
                  />
                  <span className="dialog-error login-error">
                    {errorPassword}
                  </span>
                  <LoginFormInput
                    type="password"
                    id="confirm-password"
                    placeholder="Confirm New Password"
                    autoComplete={false}
                    icon="password-input-icon"
                    onChange={handleConfirmPassword}
                  />
                  <span className="dialog-error login-error">{error}</span>
                  <input type="hidden" name="reset_code" value={reset_code} />
                  <LoginButton id="login" value={"Reset Password"} />
                </form>
                <LinkButton
                  to="/auth/login"
                  class="forget-password-text"
                  value={"Back to Login"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
