import * as React from 'react';

type ModalDialogProps = {
  id: string;
  className?: string;
  showOnLoad?: boolean
}

export const ModalDialog:React.FC<React.PropsWithChildren<ModalDialogProps>> = (props: React.PropsWithChildren<ModalDialogProps>) => {
  // Add Implementation-additional class names
  let overlayClass = "modal fade add-user-popup";
  if (props.showOnLoad) {
    overlayClass += " in";
  }
 
  return(
    <>
    <div className={overlayClass} id={props.id} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="dialog">
        <div className="modal-content">
          {props.children}
        </div>
      </div>
    </div>
    </>
  );
}

type ModalDialogHeaderProps = {
  title?: string;
  closeButton?: boolean;
  onClose?: () => void;
}

export const ModalDialogHeader:React.FC<ModalDialogHeaderProps> = (props: ModalDialogHeaderProps) => {
  const closeDialog = (e: React.MouseEvent<HTMLElement>) => {
    props.onClose && props.onClose();
    // Get the Parent Dialog
    let parentDialog = e.currentTarget.parentElement?.parentElement?.parentElement;

    // Add the Class
    parentDialog?.classList.remove("in");

    // Remove the Overlay
    document.body.classList.remove('modal-open');
  }

  return(
    <div className="modal-header">
      <h5 className="modal-title">{props.title}</h5>
      {props.closeButton && <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeDialog}>
          <span aria-hidden="true">&times;</span>
      </button>
      }
    </div>
  );
}

type ModalDialogContentProps = {}

export const ModalDialogContent:React.FC<React.PropsWithChildren<ModalDialogContentProps>> = (props: React.PropsWithChildren<ModalDialogContentProps>) => {
  return(
    <div className="modal-body">
      {props.children}
    </div>
  );
}